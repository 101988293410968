<template>
  <v-dialog
    v-model="open"
    max-width="700"
    scrollable
  >
    <v-card>
      <v-card-title>
        Add admin
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="max-height: 450px;" class="pt-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row no-gutters>
            <v-col cols="6" class="px-1">
              <v-text-field
                v-model="candidate.employee_id"
                label="Employee id *"
                name="employee id"
                outlined :rules="nameRules"
                    required
                dense
                @change="userData.employee_id = candidate.employee_id"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="First name *"
                name="first name"
                v-model="candidate.first_name"
                outlined :rules="nameRules"
                    required
                dense
                @change="userData.first_name = candidate.first_name"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Last name"
                name="last name"
                dense
                v-model="candidate.last_name"
                outlined
                @change="userData.last_name = candidate.last_name"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Email *"
                name="email"
                v-model="candidate.email"
                outlined :rules="emailRules"
                    required
                dense
                @change="userData.email = candidate.email"
              ></v-text-field>
              <div class="phone-error" style="margin-top:-24px; margin-bottom:5px;" v-if="!isEmailValid">Employee email is invalid!</div>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-select
                :items="list.gender"
                v-model="candidate.gender"
                name="gender"
                label="Gender *"
                item-text="title"
                outlined :rules="nameRules"
                    required
                dense
                @change="userData.gender = candidate.gender"
              ></v-select>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-menu
                v-model="joinDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Joining date *"
                    outlined :rules="nameRules"
                    required
                    dense
                    @input="changeFormat()"
                    readonly
                    name="joining date"
                    v-model="candidate.joining_date "
                    @blur="date = parseDate(candidate.joining_date)"
                    v-on="on"
                    @change="userData.joining_date = candidate.joining_date"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="joining_date " @input="joinDateMenu = false, changeFormat()">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Location *"
                name="location"
                v-model="candidate.location"
                outlined :rules="nameRules"
                    required
                dense
                @change="userData.location = candidate.location"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Department *"
                v-model="candidate.department"
                outlined
                name="department" :rules="nameRules"
                    required
                dense
                @change="userData.department = candidate.department"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="pb-4 heading black--text">Other fields</div>
          <v-row no-gutters>
            <v-col cols="6" class="px-1">
              <v-menu
                v-model="birthDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Birth date"
                    outlined
                    dense
                    hide-details
                    readonly
                    @input="changeFormatDOB()"
                    name="birth date"
                    v-model="candidate.birthDate"
                    @change="userData.birthDate = candidate.birthDate"
                    @blur="date = parseDate(candidate.birthDate)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="birth_date"
                  @input="checkAge(birth_date);changeFormatDOB();birthDateMenu = false;"
                ></v-date-picker>
              </v-menu>
              <div class="mb-2">
                <div class="phone-error" v-if="!isDOBValid">Employee age should be above 18 years</div>
              </div>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Alternate email"
                outlined
                dense
                name="alternate email"
                v-validate="'email'"
                :rules="candidate.alternateEmail ? emailRules : ''"
                v-model="candidate.alternateEmail"
                @change="userData.alternateEmail = candidate.alternateEmail"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Education"
                outlined
                dense
                name="education"
                v-model="candidate.education"
                @change="userData.education = candidate.education"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Experience"
                name="experience"
                outlined
                dense
                v-model="candidate.experience"
                @change="userData.experience = candidate.experience"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Designation"
                v-model="candidate.designation"
                @change="userData.designation = candidate.designation"
                outlined
                name="designation"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Skills"
                outlined
                dense
                name="skills"
                v-model="candidate.skills"
                @change="userData.skills = candidate.skills"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Grade"
                outlined
                name="grade"
                dense
                v-model="candidate.grade"
                @change="userData.grade = candidate.grade"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Sub department"
                outlined
                name="sub department"
                dense
                v-model="candidate.sub_department"
                @change="userData.sub_department = candidate.sub_department"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Manager"
                outlined
                name="manager"
                dense
                v-model="candidate.manager"
                @change="userData.manager = candidate.manager"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Function"
                outlined
                name="function"
                dense
                v-model="candidate.function"
                @change="userData.function = candidate.function"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Business"
                outlined
                dense
                name="business"
                v-model="candidate.business"
                @change="userData.business = candidate.business"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Team"
                outlined
                name="team"
                dense
                v-model="candidate.team"
                @change="userData.team = candidate.team"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Business head"
                outlined
                name="business head"
                dense
                v-model="candidate.businessHead"
                @change="userData.businessHead = candidate.businessHead"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Team head"
                outlined
                name="team head"
                dense
                v-model="candidate.teamHead"
                @change="userData.teamHead = candidate.teamHead"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="HRBP"
                outlined
                dense
                name="hrbp"
                v-validate="'email'"
                v-model="candidate.hrbp"
                :rules="candidate.hrbp ? emailRules : ''"
                @change="userData.hrbp = candidate.hrbp"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="HR Manager"
                outlined
                name="hr manger"
                dense
                v-model="candidate.hr_manager"
                :rules="candidate.hr_manager ? emailRules : ''"
                @change="userData.hr_manager = candidate.hr_manager"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Site"
                outlined
                name="site"
                dense
                v-model="candidate.site"
                @change="userData.site = candidate.site"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Cluster"
                outlined
                name="cluster"
                dense
                v-model="candidate.cluster"
                @change="userData.cluster = candidate.cluster"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Cost Centre"
                outlined
                name="cost centre"
                dense
                v-model="candidate.cost_centre"
                @change="userData.cost_centre = candidate.cost_centre"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-menu
                ref="shiftStartTime"
                v-model="shiftStartTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="candidate.shiftStartTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="candidate.shiftStartTime"
                    @change="userData.shiftStartTime = candidate.shiftStartTime"
                    label="Shift start time"
                    prepend-inner-icon="mdi-briefcase-clock-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="shiftStartTimeMenu"
                  v-model="candidate.shiftStartTime"
                  full-width
                  @click:minute="$refs.shiftStartTime.save(candidate.shiftStartTime);shiftStartTimeMenu = false;"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-menu
                ref="shiftEndTimeMenu"
                v-model="shiftEndTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="candidate.shiftEndTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="candidate.shiftEndTime"
                    @change="userData.shiftEndTime = candidate.shiftEndTime"
                    label="Shift end time"
                    prepend-inner-icon="mdi-briefcase-clock-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="shiftEndTimeMenu"
                  v-model="candidate.shiftEndTime"
                  full-width
                  @click:minute="$refs.shiftEndTimeMenu.save(candidate.shiftEndTime);shiftEndTimeMenu = false;"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-menu
                v-model="exitDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Exit date"
                    outlined
                    dense
                    readonly
                    v-model="candidate.exit_date"
                    @change="userData.exit_date = candidate.exit_date"
                    @blur="date = parseDate(candidate.exit_date)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="exit_date" @input="exitDateMenu = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-menu
                v-model="RegisnationDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Resignation date"
                    outlined
                    readonly
                    dense
                    v-model="candidate.resignation_date"
                    @change="userData.resignation_date = candidate.resignation_date"
                    @blur="date = parseDate(candidate.resignation_date)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="resignation_date" @input="RegisnationDateMenu = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-select
                :items="list.hiPos"
                v-model="candidate.high_potential_emp"
                @change="userData.high_potential_emp = candidate.high_potential_emp"
                name="hipo"
                label="High potential employee"
                item-text="title"
                class="elevation-0 grey--text text--lighten-2"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Migrated Mood"
                outlined
                name="migrated mood"
                dense
                v-model="candidate.migrated_mood"
                @change="userData.migrated_mood = candidate.migrated_mood"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Migrated Engagement Score"
                outlined
                name="migrated engagement score"
                dense
                v-model="candidate.migrated_engagement_score"
                @change="userData.migrated_engagement_score = candidate.migrated_engagement_score"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                label="Migrated Last Touchpoint"
                outlined
                name="migrated last touchpoint"
                dense
                v-model="candidate.migrated_last_touchpoint"
                @change="userData.migrated_last_touchpoint = candidate.migrated_last_touchpoint"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          @click="open = false;cancelDialogBox();"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!editView" :loading="loading" style="color:white;"
          color="#0ab39c"
          class="white--text"
          @click="validate()"
        >
          Upload
        </v-btn>
        <v-btn v-if="editView" style="color:white;"
          color="#0ab39c" :loading="loading"
          class="white--text"
          @click="updateValidate()"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'UploadSingleEmployee',
  data () {
    return {
      copyCandidateData: {},
      userData: {},
      isEmailValid: true,
      loading: false,
      whitelistError: '',
      joinDateMenu: false,
      open: false,
      editView: false,
      dateFormatted: '',
      joining_date: '',
      birth_date: '',
      exit_date: '',
      resignation_date: '',
      date: '',
      phone: null,
      countryCode: null,
      phoneIsValid: true,
      nameRules: [
        v => !!v || 'This field is required',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'E-mail must be valid',
      ],
      list: {
        gender: [{
          title: 'Male',
          value: 'male'
        }, {
          title: 'Female',
          value: 'female'
        }, {
          title: 'Other',
          value: 'other'
        }, {
          title: 'Not Disclosed',
          value: 'notDisclosed'
        }],
        types: [{
          title: 'Manager',
          value: 'manager'
        }, {
          title: 'Team Employee',
          value: 'staff'
        }],
        hiPos: [
          {
            title: 'True',
            value: 1
          },
          {
            title: 'False',
            value: 0
          }
        ]
      },
      candidate: {
        first_name: '',
        last_name: '',
        email: '',
        phoneNumber: '',
        type: 'staff',
        sub_type: 'staff',
        gender: ''
      },
      isDOBValid: true,
      shiftStartTimeMenu: false,
      shiftEndTimeMenu: false,
      exitDateMenu: false,
      RegisnationDateMenu: false,
      birthDateMenu: false,
      valid: true,
    };
  },
  computed: {
    ...mapState({
      snackbar: state => state.snackbar,
      user: state => state.user
    })
  },
  methods: {
    cancelDialogBox () {
      this.$refs.form.resetValidation();
      this.$parent.getAdmins(this.$route.query.cid);
    },
    validate () {
    this.loading = true;
    this.$refs.form.validate()
    setTimeout(() => {
      if (this.valid) {
          this.save();
      } else {
        this.loading = false;
      }
    }, 1000);
    },
    updateValidate () {
      this.loading = true;
      this.$refs.form.validate()
      setTimeout(() => {
        if (this.valid) {
            this.updateAdmin();
        } else {
          this.loading = false;
        }
      }, 1000);
    },
    checkAge (date) {
      const fData = dayjs(date).format('DD/MM/YYYY');
      this.isDOBValid = false;
      const parts = fData.split('/');
      const dtDOB = new Date(parts[1] + '/' + parts[0] + '/' + parts[2]);
      const dtCurrent = new Date();
      if (dtCurrent.getFullYear() - dtDOB.getFullYear() < 17) {
        this.isDOBValid = false;
      }
      if (dtCurrent.getFullYear() - dtDOB.getFullYear() === 17) {
        if (dtCurrent.getMonth() < dtDOB.getMonth()) {
          this.isDOBValid = false;
        }
        if (dtCurrent.getMonth() === dtDOB.getMonth()) {
          if (dtCurrent.getDate() < dtDOB.getDate()) {
            this.isDOBValid = false;
          }
        }
      }
      if (dtCurrent.getFullYear() - dtDOB.getFullYear() > 17) {
        this.isDOBValid = true;
      }
    },
    countrySelected (val) {
      this.countryCode = val.dialCode;
    },
    onInput (number, isValid) {
      if (isValid.isValid) {
        this.phoneIsValid = true;
        this.candidate.phoneNumber = isValid.number.e164;
      } else {
        this.phoneIsValid = false;
      }
    },
    
    validateAll () {
      this.$validator.validateAll().then((res) => {
        if (res) {
          if (!this.phoneIsValid) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Fields necessary, Please resolve all the errors!'
            });
            return;
          }
          if (this.isDOBValid) {
            this.save();
          }
          if (!this.isDOBValid) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Eligibility 18 years old only!'
            });
          }
        } else {
          if (!this.contactSales.contact_phone || this.contactSales.contact_phone === '') {
            this.phoneIsValid = false;
          }
          this.$store.dispatch('updateSnackbar', {
            color: 'info',
            show: true,
            text: 'Fields necessary, Please resolve all the errors!'
          });
        }
      });
    },
    updateAdmin () {
        // this.candidate.type = 'SuperAdmin';
        this.candidate.sub_type = 'SuperAdmin';
        let copiedTemp = {};
        for (const key in this.candidate) {
          if (this.candidate.hasOwnProperty(key) && this.candidate[key] !== this.copyCandidateData[key] && key !== 'user_tags' && key !== 'tags') {
            copiedTemp[key] = this.candidate[key];
          } else if (this.candidate.hasOwnProperty(key) && JSON.stringify(this.candidate[key]) !== JSON.stringify(this.copyCandidateData[key]) && (key === 'user_tags' || key === 'tags')) {
            copiedTemp[key] = this.candidate[key];
          }
        }
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
      axios.patch(`${process.env.VUE_APP_API_URL}users/update/${this.candidate.id}`, {
        ...copiedTemp
      }).then((response) => {
        this.loading = false;
        this.open = false;
        this.$store.dispatch('updateSnackbar', {
          color: 'success',
          show: true,
          text: 'Admin added successfully!'
        });
        this.$parent.getAdmins(this.$route.query.cid);
        this.getAdmins(this.$route.query.cid)
        if (response && response.data && response.data.status === 200 &&
        (!response.data.Failed_list || response.data.Failed_list.length === 0)) {
          this.$store.dispatch('updateSnackbar', {
            color: 'info',
            show: true,
            text: 'Employee uploaded!'
          });
          this.open = false;
          this.closeDialog();
        } else if (response.data.Failed_list && response.data.Failed_list.length > 0) {
          this.loading = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: response.data.Failed_list[0].validation_error
          });
          this.loading = false;
          if (response.data.Failed_list[0].validation_error.split('[')[1] === '"Employee email invalid: ') {
            this.loading = false;
            this.isEmailValid = false;
            this.whitelistError = response.data.Failed_list[0].validation_error;
          }
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to upload admin, Please try again later!'
          });
          this.loading = false;
        }
        this.$forceUpdate();
      }, (response) => {
        this.loading = false;
        this.$store.dispatch('updateSnackbar', {
          color: 'error',
          show: true,
          text: 'Unable to upload admin, Please try again later!'
        });
        throw new Error(response);
      });
    },
    save () {
        this.candidate.type = 'SuperAdmin';
        this.candidate.sub_type = 'SuperAdmin';
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
      axios.post(`${process.env.VUE_APP_API_URL_FB}users/bulk_add?response_type=sync&upload_type=single_upload`, {
        user_data: [this.candidate],
        company_id: this.$route.query.cid,
      }).then((response) => {
        if (response.data.message) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: `${response.data.message}`
          });
        }
        if (response && response.data && response.data.status === 200 &&
        (!response.data.Failed_list || response.data.Failed_list.length === 0)) {
          this.$store.dispatch('updateSnackbar', {
            color: 'info',
            show: true,
            text: 'Admin added successfully!'
          });
          this.loading = false;
          this.open = false;
          this.$parent.getAdmins(this.$route.query.cid);
          this.getAdmins(this.$route.query.cid);
          this.open = false;
          this.closeDialog();
        } else if (response.data.Failed_list && response.data.Failed_list.length > 0) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: response.data.Failed_list[0].validation_error
          });
          this.loading = false;
          if (response.data.Failed_list[0].validation_error.split('[')[1] === '"Employee email invalid: ') {
            this.isEmailValid = false;
            this.whitelistError = response.data.Failed_list[0].validation_error;
          }
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to upload admin, Please try again later!'
          });
          this.loading = false;
        }
      }, (response) => {
        this.$store.dispatch('updateSnackbar', {
          color: 'error',
          show: true,
          text: 'Unable to upload admin, Please try again later!'
        });
        throw new Error(response);
      });
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    changeFormat () {
      if (this.candidate.joining_date) {
        const [year, month, day] = this.candidate.joining_date.split('-');
        this.candidate.joining_date = `${day}-${month}-${year}`;
      }
    },
    parseDate (date) {
      if (!date) return null;
      const [day, month, year] = date.split('-');
      return `${year}-${month}-${day}`;
    },
    closeDialog () {
      this.candidate = {
        first_name: '',
        last_name: '',
        email: '',
        phoneNumber: '',
        type: 'staff',
        sub_type: 'staff',
        gender: ''
      };
      this.errors.clear();
      this.open = false;
    }
  },
  watch: {
    open () {
      if (this.open === true) {
        setTimeout(() => {
          this.copyCandidateData = JSON.parse(JSON.stringify(this.candidate));
        }, 1000);
        
      }
    },
    joining_date (val) {
      const [year, month, day] = this.joining_date.split('-');
      this.candidate.joining_date = `${day}-${month}-${year}`;
    },
    birth_date (val) {
      const [year, month, day] = this.birth_date.split('-');
      this.candidate.birthDate = `${day}-${month}-${year}`;
    },
    resignation_date (val) {
      const [year, month, day] = this.resignation_date.split('-');
      this.candidate.resignation_date = `${day}-${month}-${year}`;
    },
    exit_date (val) {
      const [year, month, day] = this.exit_date.split('-');
      this.candidate.exit_date = `${day}-${month}-${year}`;
    }
  },
};
</script>

<style lang="scss">
.emp-phone-field {
  border-radius: 8px !important;
  &.ph-error {
    border: 2px solid red;
    ::-webkit-input-placeholder {
      color: red !important;
    }
  }
}
.phone-error {
  font-size: 12px;
  color: red;
}
</style>
