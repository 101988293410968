<template>
    <v-dialog max-width="1000px" style="max-height:450px;"
        v-model="dialogs.newQuestion"
        transition="dialog-transition"
      >
        <v-card style="max-height:100%; max-width:1000px;">
          <v-card-text style="padding-top:10px">
            <h2 v-if="selectedQuesType && selectedQuesType[0] &&
            selectedQuesType[0].type === 'text'">  Update a statement </h2>
            <h2 v-if="!lang && (!selectedQuesType || !selectedQuesType[0] ||
            selectedQuesType[0].type !== 'text')"> Question </h2>
            <h2 v-if="lang && !editMLFlag && (!selectedQuesType || !selectedQuesType[0] ||
            selectedQuesType[0].type !== 'text')"> Question In {{lang.name}}</h2>
            <h2 v-if="lang && editMLFlag && (!selectedQuesType || !selectedQuesType[0] ||
            selectedQuesType[0].type !== 'text')"> Question In {{lang.name}}</h2>
            <div class="mx-2 mt-2 d-flex flex-row flex-wrap" :reverse="ifSmall" v-if="selectedQuesType" style="max-height: calc( 100% - 30px);overflow-y: auto;">
              <v-flex class="interactions">
                <v-card class="elevation-0 mt-2 interaction-card">
  
                  <div v-if="!editLang" class="d-flex flex-row flex-wrap grey lighten-5 drag-container" style="height:100%">
                    <v-flex xs1></v-flex>
                    <v-flex xs10>
                      <h3 class="ml-4 mt-3" v-if="selectedQuesType && selectedQuesType[0]">
                        {{selectedQuesType[0].title}}
                      </h3>
                      <div v-for="(q, qi) in selectedQuesType" :key="qi">
                        <div class="flex-row d-flex draggable-item" style="max-height:350px;">
                          <v-flex class="ma-4 action-card text-center" sm12>
                            <div class="title text-capitalize d-flex flex-column">
                              {{q.title}}
                            </div>
                            <v-card class="mb-1 elevation-0 " >
                              <div v-if="q.type !== 'text'" class="d-flex flex-row flex-wrap">
                                <v-flex xs2  class="font-weight-bold pl-2 pt-2 pl-2 mt-1 text-left" v-if="q.driver !== 'Mood'">
                                  Driver
                                </v-flex>
                                <v-flex lay class="text-left" xs4 v-if="q.driver !== 'Mood'">
                                  <v-select
                                    class="remove-msg"
                                    attach
                                    :items="drivers"
                                    item-text="name"
                                    item-value="value"
                                    @change="getSubDrivers(q.driver);checkDriver = q.driver;"
                                    chips
                                    small-chips
                                    :id="`role_${qi}`"
                                    :name="`role_${qi}`"
                                    data-vv-as="driver"
                                    solo flat
                                    :error-messages="errors.collect(`role_${qi}`)"
                                    v-model="q.driver"
                                    :v-validate="{'required': selectedQuesType && selectedQuesType[0] && selectedQuesType[0].type !== 'textInput'}"
                                    label="Select from the list"
                                  ></v-select>
                                </v-flex>
  
                                <v-flex xs2 v-if="q.driver !== 'Mood'" class="font-weight-bold pl-2 pt-2 mt-1 text-left">
                                  Sub Driver
                                </v-flex>
                                <v-flex lay class="text-left pr-2" xs4 v-if="q.driver !== 'Mood'">
                                  <v-select
                                    class="remove-msg"
                                    :items="subDrivers"
                                    item-text="name"
                                    item-value="value"
                                    chips
                                    attach
                                    small-chips
                                    :id="`subrole_${qi}`"
                                    :name="`subrole_${qi}`"
                                    data-vv-as="subdriver"
                                    solo flat
                                    @change="checkSubDriver = q.sub_driver;"
                                    :error-messages="errors.collect(`subrole_${qi}`)"
                                    v-model="q.sub_driver"
                                    :v-validate="{'required': selectedQuesType && selectedQuesType[0] && selectedQuesType[0].type !== 'textInput'}"
                                    label="Select from the list"
                                  ></v-select>
                                </v-flex>
  
                                <v-flex xs12>
                                  <v-divider></v-divider>
                                </v-flex>
                              </div>
  
                              <div class="pt-4 pa-3 d-flex flex-row flex-wrap align-center">
  
                                <!-- Scale -->
                                <v-flex  xs10 offset-xs1 v-if="q.type === 'scale'">
                                  <v-text-field
                                    v-if="q.msg"
                                    maxlength="300"
                                    v-model="q.msg[0].text"
                                    class="elevation-0 grey--text text--lighten-2"
                                    name="interactionText"
                                    :error-messages="errors.collect('interactionText')"
                                    label="Question"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    outlined
                                  >
                                  </v-text-field>
  
                                  <div class="d-flex flex-row flex-wrap justify-center"
                                  v-if="q.msg && q.msg[0].scaleType === 'emoji'">
                                    <v-flex xs10 class="mb-3 text-center"
                                    v-for="(r, ri) in q.msg[0].scale" :key="ri">
                                      <div class="d-flex flex-row">
                                        <v-flex xs3>
                                          <v-btn class="emoji-btn"
                                            slot="activator"
                                            fab text rounded
                                          >
                                            <img v-if="r.img" :src="getImgUrl(r.img)"
                                            class="emoji-container" alt="Image"/>
                                          </v-btn>
                                          <p class="text-center mt-2">
                                            {{r.title}}
                                          </p>
                                        </v-flex>
  
                                        <v-flex xs9>
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            style="position:relative;top:-10px"
                                            maxlength="50"
                                            v-model="r.title"
                                            flat
                                            outline
                                            @click.stop
                                            name="quickReply"
                                            :label="`Rating ${ri+1}`"
                                            v-validate="'required'"
                                          ></v-text-field>
  
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            style="position:relative;top:-10px"
                                            maxlength="300"
                                            v-model="r.postback"
                                            flat
                                            outline
                                            @click.stop
                                            name="postback"
                                            label="Amara's response"
                                            v-validate="'required'"
                                          ></v-text-field>
                                        </v-flex>
  
                                      </div>
  
                                    </v-flex>
                                  </div>
  
                                  <div class="d-flex flex-row flex-wrap justify-center"
                                  v-if="q.msg && q.msg[0].scaleType === 'number'">
                                    <v-flex xs10 class="mb-3 text-center"
                                    v-for="(r, ri) in q.msg[0].scale" :key="ri">
                                      <div class="d-flex flex-row">
                                        <v-flex xs3>
                                          <v-btn class="emoji-btn"
                                            :color="r.color"
                                            fab
                                            outlined
                                            rounded
                                            slot="activator"
                                          >
                                            <strong class="headline">
                                              {{r.value}}
                                            </strong>
                                          </v-btn>
  
                                          <p class="text-center">
                                            {{r.title}}
                                          </p>
                                        </v-flex>
  
                                        <v-flex xs9>
                                          <v-text-field class="grey--text text--lighten-2
                                          template-custom-field no-margin mb-2"
                                            maxlength="50"
                                            outline
                                            v-model="r.title"
                                            flat
                                            @click.stop
                                            name="quickReply"
                                            :label="`Rating ${ri+1}`"
                                            v-validate="'required'"
                                          ></v-text-field>
  
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            maxlength="300"
                                            outline
                                            v-model="r.postback"
                                            flat
                                            @click.stop
                                            name="postback"
                                            label="Amara's response"
                                            v-validate="'required'"
                                          ></v-text-field>
                                        </v-flex>
                                      </div>
                                    </v-flex>
                                  </div>
                                </v-flex>
  
                                <!-- Input from user -->
                                <v-flex  xs10 offset-xs1 v-if="q.type === 'textInput'">
                                  <v-text-field
                                    maxlength="300"
                                    v-model="q.msg['0'].text"
                                    class="elevation-0 grey--text text--lighten-2"
                                    :name="`interactionText_${qi}`"
                                    :error-messages="errors.collect(`interactionText_${qi}`)"
                                    label="Question"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    single-line
                                    outline
                                    @change="checkDriver = q.driver; checkSubDriver = q.sub_driver;"
                                  >
                                    <v-fade-transition slot="prepend">
                                      <img width="30" height="30"
                                      :src="getImgUrl('amara-60')" alt="Image">
                                    </v-fade-transition>
                                  </v-text-field>
                                </v-flex>
  
                                <!-- Text -->
                                <v-flex  xs10 offset-xs1 v-if="q.type === 'text'">
                                  <v-text-field
                                    maxlength="300"
                                    v-model="q.msg['0'].text"
                                    class="elevation-0 grey--text text--lighten-2"
                                    :name="`interactionText_${qi}`"
                                    :error-messages="errors.collect(`interactionText_${qi}`)"
                                    label="What should Amara say?"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    single-line
                                    outlined
                                  >
                                    <v-fade-transition slot="prepend">
                                      <img width="30" height="30"
                                      :src="getImgUrl('amara-60')" alt="Image">
                                    </v-fade-transition>
                                  </v-text-field>
                                </v-flex>
  
                                <!-- Image -->
                                <v-flex  v-if="q.type === 'image'">
                                  <v-menu
                                    absolute
                                    v-if="q.msg"
                                    offset-y
                                    style="max-width: 600px"
                                  >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-img
                                      height="200"
                                      width="300"
                                      v-bind="attrs"
                                      v-on="on"
                                      slot="activator"
                                      max-height="200"
                                      max-width="300"
                                      :src="q.msg[0].imgUrl"
                                      :lazy-src="q.msg[0].imgUrl"
                                      aspect-ratio="1"
                                      class="grey lighten-2 mx-auto"
                                    >
                                      <div
                                        class="d-flex flex-row fill-height align-center justify-center ma-0"
                                        slot="placeholder"
                                      >
                                        <div >
                                          <v-icon class="mr-2">fa-regular fa-plus-square</v-icon>
                                          <strong class="mb-2">
                                            Add Image
                                          </strong>
                                        </div>
                                        <v-progress-circular v-show="q.msg[0].imgUrl"
                                        indeterminate color="grey lighten-5">
                                        </v-progress-circular>
                                      </div>
                                    </v-img>
                                  </template>
                                    <v-list class="pa-3">
                                      <v-text-field
                                        maxlength="1000"
                                        outlined
                                        v-model="q.msg[0].imgUrl"
                                        flat
                                        @click.stop
                                        name="url"
                                        label="Your image URL"
                                      ></v-text-field>
                                      <v-flex xs12 class="text-right">
                                        <v-btn color="#0ab39c" class="white--text" small>Done</v-btn>
                                      </v-flex>
                                    </v-list>
                                  </v-menu>
                                </v-flex>
  
                                <!-- Card -->
                                <v-flex  v-if="q.type === 'card'">
                                  <v-card class="mx-auto" width="300"
                                    v-for="(c, ci) in q.msg" :key="ci"
                                  >
                                    <v-menu
                                      absolute
                                      offset-y
                                      style="max-width: 600px"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-img
                                        height="200"
                                        width="300"
                                        slot="activator"
                                        max-height="200"
                                        max-width="300"
                                        v-bind="attrs"
                                        v-on="on"
                                        :src="c.imgUrl"
                                        :lazy-src="c.imgUrl"
                                        aspect-ratio="1"
                                        class="grey lighten-2 mx-auto"
                                      >
                                        <div class="d-flex flex-row fill-height align-center justify-center ma-0"
                                          slot="placeholder"
                                        >
                                          <div >
                                            <v-icon class="mr-2">fa-regular fa-plus-square</v-icon>
                                            <strong class="mb-2">
                                              Add Image
                                            </strong>
                                          </div>
                                          <v-progress-circular v-show="c.imgUrl"
                                          indeterminate color="grey lighten-5">
                                          </v-progress-circular>
                                        </div>
                                      </v-img>
                                    </template>
                                      <v-list class="pa-3">
                                        <v-text-field
                                          maxlength="1000"
                                          outlined
                                          v-model="c.imgUrl"
                                          flat
                                          @click.stop
                                          name="url"
                                          label="Your image URL"
                                        ></v-text-field>
                                        <v-flex xs12 class="text-right">
                                          <v-btn color="#0ab39c" class="white--text" small>
                                            Done
                                          </v-btn>
                                        </v-flex>
                                      </v-list>
                                    </v-menu>
                                    <h3 class="headline mb-0 font-weight-bold w-100">
                                      <v-text-field
                                        maxlength="300"
                                        solo
                                        v-model="c.text"
                                        flat
                                        @click.stop
                                        name="cardTitle"
                                        label="Enter your message here"
                                      ></v-text-field>
                                    </h3>
                                    <div>
                                      <v-textarea
                                        maxlength="500"
                                        flat
                                        rows="2"
                                        solo
                                        v-model="c.description"
                                        name="cardDescription"
                                        label="Enter card description here"
                                      ></v-textarea>
                                    </div>
  
                                    <v-card-actions>
                                      <div class="text-left">
                                        <v-menu v-for="(r, ri) in c.quickReplies" :key="ri"
                                          absolute
                                          offset-y
                                          class="mb-1"
                                          style="width: 20em;"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn color="#0ab39c" block outlined slot="activator" v-bind="attrs" v-on="on">
                                            {{r.title}}
                                          </v-btn>
                                        </template>
                                          <v-list class="pa-3">
                                            <v-text-field
                                              class="grey--text text--lighten-2"
                                              maxlength="50"
                                              outlined
                                              v-model="r.title"
                                              flat
                                              @click.stop
                                              name="quickReply"
                                              :label="`Rating ${ri+1}`"
                                            ></v-text-field>
  
                                            <v-text-field
                                              class="grey--text text--lighten-2"
                                              maxlength="300"
                                              outlined
                                              v-model="r.postback"
                                              flat
                                              @click.stop
                                              name="postback"
                                              label="Amara's response"
                                            ></v-text-field>
  
                                            <v-flex xs12 class="text-right">
                                              <v-btn color="error" small text
                                              @click="deleteReply(qi, ci, ri)">Delete</v-btn>
                                              <v-btn color="#0ab39c" class="white--text" small>
                                                Done
                                              </v-btn>
                                            </v-flex>
                                          </v-list>
                                        </v-menu>
  
                                        <p class="cursor-pointer mb-0 text-sm-center"
                                        v-if="c.quickReplies.length < 5"
                                        style="width: 20em;" @click="addReplies(qi, ci, 'Button')">
                                          <v-btn color="accent" class="mr-2" small outlined icon>
                                            <v-icon small>fa-regular fa-plus</v-icon>
                                          </v-btn>Add reply
                                        </p>
                                      </div>
                                    </v-card-actions>
                                  </v-card>
                                </v-flex>
  
                                <v-flex  v-if="q.type === 'carousel'">
                                  <div class="d-flex flex-wrap flex-row">
                                    <v-card class="mr-3" width="300"
                                      v-for="(c, ci) in q.msg" :key="ci"
                                    >
                                      <v-menu
                                        absolute
                                        offset-y
                                        style="max-width: 600px"
                                      >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-img
                                          height="200"
                                          width="300"
                                          slot="activator"
                                          v-bind="attrs"
                                          v-on="on"
                                          max-height="200"
                                          max-width="300"
                                          :src="c.imgUrl"
                                          :lazy-src="c.imgUrl"
                                          aspect-ratio="1"
                                          class="grey lighten-2 mx-auto"
                                        >
                                          <div
                                            class="d-flex flex-row fill-height align-center justify-center ma-0"
                                            slot="placeholder"
                                          >
                                            <div >
                                              <v-icon class="mr-2">fa-regular fa-plus-square</v-icon>
                                              <strong class="mb-2">
                                                Add Image
                                              </strong>
                                            </div>
                                            <v-progress-circular v-show="c.imgUrl"
                                            indeterminate color="grey lighten-5">
                                            </v-progress-circular>
                                          </div>
                                        </v-img>
                                      </template>
                                        <v-list class="pa-3">
                                          <v-text-field
                                            maxlength="1000"
                                            outlined
                                            v-model="c.imgUrl"
                                            flat
                                            @click.stop
                                            name="url"
                                            label="Your image URL"
                                          ></v-text-field>
                                          <v-flex xs12 class="text-right">
                                            <v-btn color="#0ab39c" class="white--text" small>
                                              Done
                                            </v-btn>
                                          </v-flex>
                                        </v-list>
                                      </v-menu>
                                      <h3 class="headline mb-0 font-weight-bold w-100">
                                        <v-text-field
                                          maxlength="300"
                                          solo
                                          v-model="c.text"
                                          flat
                                          @click.stop
                                          name="cardTitle"
                                          label="Enter your message here"
                                        ></v-text-field>
                                      </h3>
                                      <div>
                                        <v-textarea
                                          maxlength="500"
                                          flat
                                          rows="2"
                                          solo
                                          v-model="c.description"
                                          name="cardDescription"
                                          label="Enter card description here"
                                        ></v-textarea>
                                      </div>
  
                                      <v-card-actions>
                                        <div class="text-left">
                                          <v-menu v-for="(r, ri) in c.quickReplies" :key="ri"
                                            absolute
                                            offset-y
                                            class="mb-1"
                                            style="width: 20em;"
                                          >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="#0ab39c" block outlined slot="activator" v-bind="attrs" v-on="on">
                                              {{r.title}}
                                            </v-btn>
                                          </template>
                                            <v-list class="pa-3">
                                              <v-text-field
                                                class="grey--text text--lighten-2"
                                                maxlength="50"
                                                outlined
                                                v-model="r.title"
                                                flat
                                                @click.stop
                                                name="quickReply"
                                                :label="`Rating ${ri+1}`"
                                              ></v-text-field>
  
                                              <v-text-field
                                                class="grey--text text--lighten-2"
                                                maxlength="300"
                                                outlined
                                                v-model="r.postback"
                                                flat
                                                @click.stop
                                                name="postback"
                                                label="Amara's response"
                                              ></v-text-field>
  
                                              <v-flex xs12 class="text-right">
                                                <v-btn color="error" small text
                                                @click="deleteReply(qi, ci, ri)">Delete</v-btn>
                                                <v-btn color="#0ab39c" class="white--text" small>
                                                  Done
                                                </v-btn>
                                              </v-flex>
                                            </v-list>
                                          </v-menu>
  
                                          <!-- Add new quick reply -->
                                          <p class="cursor-pointer mb-0 text-sm-center"
                                          v-if="c.quickReplies.length < 5"
                                          style="width: 20em;" @click="addReplies(qi, ci, 'Button')">
                                            <v-btn color="accent" class="mr-2" small outlined icon>
                                              <v-icon small>fa-regular fa-plus</v-icon>
                                            </v-btn>Add reply
                                          </p>
                                        </div>
                                      </v-card-actions>
                                    </v-card>
                                    <v-card>
                                      <p class="cursor-pointer mb-0 text-sm-center"
                                      v-if="q.msg.length < 5"
                                      style="width: 20em;" @click="addCard(qi)">
                                        <v-btn color="accent" class="mr-2" small outlined icon>
                                          <v-icon small>fa-regular fa-plus</v-icon>
                                        </v-btn>Add card
                                      </p>
                                    </v-card>
                                  </div>
                                </v-flex>
  
                                <v-flex  xs10 offset-xs1
                                v-if="q.type === 'quickReplies' || q.type === 'closeEnded'">
                                  <v-text-field
                                    v-if="q.msg"
                                    maxlength="300"
                                    class="elevation-0 grey--text text--lighten-2"
                                    v-model="q.msg[0].text"
                                    :name="`interactionText`"
                                    :error-messages="errors.collect('interactionText')"
                                    label="Question"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    single-line
                                    outlined
                                  >
                                    <v-fade-transition slot="prepend">
                                      <img width="30" height="30"
                                      :src="getImgUrl('amara-60')" alt="Image">
                                    </v-fade-transition>
                                  </v-text-field>
  
                                  <div
                                    class="d-flex flex-row flex-wrap text-left justify-center" v-if="q.msg"
                                  >
                                    <v-flex xs10 class="mb-3 text-center"
                                      v-for="(c, ci) in q.msg[0].quickReplies" :key="ci">
                                      <div class="d-flex flex-row">
                                        <v-flex xs3>
                                          <v-btn color="#0ab39c" outlined slot="activator">
                                          {{c.title}}</v-btn>
                                        </v-flex>
                                        <v-flex xs9>
                                          <v-text-field class="grey--text text--lighten-2
                                          template-custom-field no-margin mb-2"
                                            maxlength="50"
                                            outlined
                                            dense
                                            v-model="c.title"
                                            @click.stop
                                            name="quickReply"
                                            :label="`Rating ${ci+1}`"
                                            v-validate="'required'"
                                          ></v-text-field>
  
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            maxlength="300"
                                            outlined
                                            v-model="c.postback"
                                            @click.stop
                                            dense
                                            name="postback"
                                            label="Amara's response"
                                            v-validate="'required'"
                                          ></v-text-field>
                                        </v-flex>
                                      </div>
                                    </v-flex>
  
                                    <span class="cursor-pointer" @click="addReplies(qi, 0, 'Answer')"
                                    v-if="q.msg && q.msg[0].quickReplies.length < 5 &&
                                    q.type === 'quickReplies'">
                                      <v-btn color="accent" small outlined icon>
                                        <v-icon small>fa-regular fa-plus</v-icon>
                                      </v-btn>Add reply
                                    </span>
                                  </div>
                                </v-flex>
  
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </div>
                    </v-flex>
  
                    <v-flex xs1></v-flex>
  
                  </div>
                  <div v-if="editLang && lang.language_code === 'tl'" class="d-flex flex-row flex-wrap grey lighten-5 drag-container" style="height:100%">
                    <v-flex xs1></v-flex>
                    <v-flex xs10>
                      <h3 class="ml-4 mt-3" v-if="selectedQuesType && selectedQuesType[0]">
                        {{selectedQuesType[0].title}}
                      </h3>
                      <div v-for="(q, qi) in selectedQuesType" :key="qi" v-show="qi === '0'">
                        <div class="flex-row d-flex draggable-item" style="max-height:350px;">
                          <v-flex class="ma-4 action-card text-center" sm12>
                            <div class="title text-capitalize d-flex flex-column">
                              {{q.title}}
                            </div>
                            <v-card class="mb-1 elevation-0 " >
  
                              <div class="pt-4 pa-3 d-flex flex-row flex-wrap align-center">
  
                                <v-flex  xs10 offset-xs1 v-if="q.type === 'scale'">
                                  <v-text-field
                                    v-if="q.msg_tl"
                                    maxlength="300"
                                    v-model="q.msg_tl[0].text"
                                    class="elevation-0 grey--text text--lighten-2"
                                    name="interactionText"
                                    :error-messages="errors.collect('interactionText')"
                                    label="Question"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    outlined
                                  >
                                  </v-text-field>
  
                                  <div class="d-flex flex-row flex-wrap justify-center"
                                  v-if="q.msg_tl && q.msg_tl[0].scaleType === 'emoji'">
                                    <v-flex xs10 class="mb-3 text-center"
                                    v-for="(r, ri) in q.msg_tl[0].scale" :key="ri">
                                      <div class="d-flex flex-row">
                                        <v-flex xs3>
                                          <v-btn class="emoji-btn"
                                            slot="activator"
                                            fab text rounded
                                          >
                                            <img v-if="r.img" :src="getImgUrl(r.img)"
                                            class="emoji-container" alt="Image"/>
                                          </v-btn>
                                          <p class="text-center mt-2">
                                            {{r.title}}
                                          </p>
                                        </v-flex>
  
                                        <v-flex xs9>
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            style="position:relative;top:-10px"
                                            maxlength="50"
                                            v-model="r.title"
                                            flat
                                            outline
                                            @click.stop
                                            name="quickReply"
                                            :label="`Rating ${ri+1}`"
                                          ></v-text-field>
  
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            style="position:relative;top:-10px"
                                            maxlength="300"
                                            v-model="r.postback"
                                            flat
                                            outline
                                            @click.stop
                                            name="postback"
                                            label="Amara's response"
                                          ></v-text-field>
                                        </v-flex>
  
                                      </div>
  
                                    </v-flex>
                                  </div>
  
                                  <div class="d-flex flex-row flex-wrap justify-center"
                                  v-if="q.msg_tl && q.msg_tl[0].scaleType === 'number'">
                                    <v-flex xs10 class="mb-3 text-center"
                                    v-for="(r, ri) in q.msg_tl[0].scale" :key="ri">
                                      <div class="d-flex flex-row">
                                        <v-flex xs3>
                                          <v-btn class="emoji-btn"
                                            :color="r.color"
                                            fab
                                            outlined
                                            rounded
                                            slot="activator"
                                          >
                                            <strong class="headline">
                                              {{r.value}}
                                            </strong>
                                          </v-btn>
  
                                          <p class="text-center">
                                            {{r.title}}
                                          </p>
                                        </v-flex>
  
                                        <v-flex xs9>
                                          <v-text-field class="grey--text text--lighten-2
                                          template-custom-field no-margin mb-2"
                                            maxlength="50"
                                            outline
                                            v-model="r.title"
                                            flat
                                            @click.stop
                                            name="quickReply"
                                            :label="`Rating ${ri+1}`"
                                          ></v-text-field>
  
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            maxlength="300"
                                            outline
                                            v-model="r.postback"
                                            flat
                                            @click.stop
                                            name="postback"
                                            label="Amara's response"
                                          ></v-text-field>
                                        </v-flex>
                                      </div>
                                    </v-flex>
                                  </div>
                                </v-flex>
  
                                <v-flex  xs10 offset-xs1 v-if="q.type === 'textInput'">
                                  <v-text-field
                                    maxlength="300"
                                    v-model="q.msg_tl['0'].text"
                                    class="elevation-0 grey--text text--lighten-2"
                                    :name="`interactionText_${qi}`"
                                    :error-messages="errors.collect(`interactionText_${qi}`)"
                                    label="Question"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    single-line
                                    outline
                                  >
                                    <v-fade-transition slot="prepend">
                                      <img width="30" height="30"
                                      :src="getImgUrl('amara-60')" alt="Image">
                                    </v-fade-transition>
                                  </v-text-field>
                                </v-flex>
  
                                <v-flex  xs10 offset-xs1 v-if="q.type === 'text'">
                                  <v-text-field
                                    maxlength="300"
                                    v-model="q.msg_tl['0'].text"
                                    class="elevation-0 grey--text text--lighten-2"
                                    :name="`interactionText_${qi}`"
                                    :error-messages="errors.collect(`interactionText_${qi}`)"
                                    label="What should Amara say?"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    single-line
                                    outlined
                                  >
                                    <v-fade-transition slot="prepend">
                                      <img width="30" height="30"
                                      :src="getImgUrl('amara-60')" alt="Image">
                                    </v-fade-transition>
                                  </v-text-field>
                                </v-flex>
  
                                <v-flex  v-if="q.type === 'image'">
                                  <v-menu
                                    absolute
                                    v-if="q.msg_tl"
                                    offset-y
                                    style="max-width: 600px"
                                  >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-img
                                      height="200"
                                      width="300"
                                      v-bind="attrs"
                                      v-on="on"
                                      slot="activator"
                                      max-height="200"
                                      max-width="300"
                                      :src="q.msg_tl[0].imgUrl"
                                      :lazy-src="q.msg_tl[0].imgUrl"
                                      aspect-ratio="1"
                                      class="grey lighten-2 mx-auto"
                                    >
                                      <div
                                        class="d-flex flex-row fill-height align-center justify-center ma-0"
                                        slot="placeholder"
                                      >
                                        <div >
                                          <v-icon class="mr-2">fa-regular fa-plus-square</v-icon>
                                          <strong class="mb-2">
                                            Add Image
                                          </strong>
                                        </div>
                                        <v-progress-circular v-show="q.msg_tl[0].imgUrl"
                                        indeterminate color="grey lighten-5">
                                        </v-progress-circular>
                                      </div>
                                    </v-img>
                                  </template>
                                    <v-list class="pa-3">
                                      <v-text-field
                                        maxlength="1000"
                                        outlined
                                        v-model="q.msg_tl[0].imgUrl"
                                        flat
                                        @click.stop
                                        name="url"
                                        label="Your image URL"
                                      ></v-text-field>
                                      <v-flex xs12 class="text-right">
                                        <v-btn color="#0ab39c" class="white--text" small>Done</v-btn>
                                      </v-flex>
                                    </v-list>
                                  </v-menu>
                                </v-flex>
  
                                <v-flex  v-if="q.type === 'card'">
                                  <v-card class="mx-auto" width="300"
                                    v-for="(c, ci) in q.msg_tl" :key="ci"
                                  >
                                    <v-menu
                                      absolute
                                      offset-y
                                      style="max-width: 600px"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-img
                                        height="200"
                                        width="300"
                                        slot="activator"
                                        max-height="200"
                                        max-width="300"
                                        v-bind="attrs"
                                        v-on="on"
                                        :src="c.imgUrl"
                                        :lazy-src="c.imgUrl"
                                        aspect-ratio="1"
                                        class="grey lighten-2 mx-auto"
                                      >
                                        <div class="d-flex flex-row fill-height align-center justify-center ma-0"
                                          slot="placeholder"
                                        >
                                          <div >
                                            <v-icon class="mr-2">fa-regular fa-plus-square</v-icon>
                                            <strong class="mb-2">
                                              Add Image
                                            </strong>
                                          </div>
                                          <v-progress-circular v-show="c.imgUrl"
                                          indeterminate color="grey lighten-5">
                                          </v-progress-circular>
                                        </div>
                                      </v-img>
                                    </template>
                                      <v-list class="pa-3">
                                        <v-text-field
                                          maxlength="1000"
                                          outlined
                                          v-model="c.imgUrl"
                                          flat
                                          @click.stop
                                          name="url"
                                          label="Your image URL"
                                        ></v-text-field>
                                        <v-flex xs12 class="text-right">
                                          <v-btn color="#0ab39c" class="white--text" small>
                                            Done
                                          </v-btn>
                                        </v-flex>
                                      </v-list>
                                    </v-menu>
                                    <h3 class="headline mb-0 font-weight-bold w-100">
                                      <v-text-field
                                        maxlength="300"
                                        solo
                                        v-model="c.text"
                                        flat
                                        @click.stop
                                        name="cardTitle"
                                        label="Enter your message here"
                                      ></v-text-field>
                                    </h3>
                                    <div>
                                      <v-textarea
                                        maxlength="500"
                                        flat
                                        rows="2"
                                        solo
                                        v-model="c.description"
                                        name="cardDescription"
                                        label="Enter card description here"
                                      ></v-textarea>
                                    </div>
  
                                    <v-card-actions>
                                      <div class="text-left">
                                        <v-menu v-for="(r, ri) in c.quickReplies" :key="ri"
                                          absolute
                                          offset-y
                                          class="mb-1"
                                          style="width: 20em;"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn color="#0ab39c" block outlined slot="activator" v-bind="attrs" v-on="on">
                                            {{r.title}}
                                          </v-btn>
                                        </template>
                                          <v-list class="pa-3">
                                            <v-text-field
                                              class="grey--text text--lighten-2"
                                              maxlength="50"
                                              outlined
                                              v-model="r.title"
                                              flat
                                              @click.stop
                                              name="quickReply"
                                              :label="`Rating ${ri+1}`"
                                            ></v-text-field>
  
                                            <v-text-field
                                              class="grey--text text--lighten-2"
                                              maxlength="300"
                                              outlined
                                              v-model="r.postback"
                                              flat
                                              @click.stop
                                              name="postback"
                                              label="Amara's response"
                                            ></v-text-field>
  
                                            <v-flex xs12 class="text-right">
                                              <v-btn color="error" small text
                                              @click="deleteReply(qi, ci, ri)">Delete</v-btn>
                                              <v-btn color="#0ab39c" class="white--text" small>
                                                Done
                                              </v-btn>
                                            </v-flex>
                                          </v-list>
                                        </v-menu>
  
                                        <p class="cursor-pointer mb-0 text-sm-center"
                                        v-if="c.quickReplies.length < 5"
                                        style="width: 20em;" @click="addReplies(qi, ci, 'Button')">
                                          <v-btn color="accent" class="mr-2" small outlined icon>
                                            <v-icon small>fa-regular fa-plus</v-icon>
                                          </v-btn>Add reply
                                        </p>
                                      </div>
                                    </v-card-actions>
                                  </v-card>
                                </v-flex>
  
                                <v-flex  v-if="q.type === 'carousel'">
                                  <div class="d-flex flex-wrap flex-row">
                                    <v-card class="mr-3" width="300"
                                      v-for="(c, ci) in q.msg_tl" :key="ci"
                                    >
                                      <v-menu
                                        absolute
                                        offset-y
                                        style="max-width: 600px"
                                      >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-img
                                          height="200"
                                          width="300"
                                          slot="activator"
                                          v-bind="attrs"
                                          v-on="on"
                                          max-height="200"
                                          max-width="300"
                                          :src="c.imgUrl"
                                          :lazy-src="c.imgUrl"
                                          aspect-ratio="1"
                                          class="grey lighten-2 mx-auto"
                                        >
                                          <div
                                            class="d-flex flex-row fill-height align-center justify-center ma-0"
                                            slot="placeholder"
                                          >
                                            <div >
                                              <v-icon class="mr-2">fa-regular fa-plus-square</v-icon>
                                              <strong class="mb-2">
                                                Add Image
                                              </strong>
                                            </div>
                                            <v-progress-circular v-show="c.imgUrl"
                                            indeterminate color="grey lighten-5">
                                            </v-progress-circular>
                                          </div>
                                        </v-img>
                                      </template>
                                        <v-list class="pa-3">
                                          <v-text-field
                                            maxlength="1000"
                                            outlined
                                            v-model="c.imgUrl"
                                            flat
                                            @click.stop
                                            name="url"
                                            label="Your image URL"
                                          ></v-text-field>
                                          <v-flex xs12 class="text-right">
                                            <v-btn color="#0ab39c" class="white--text" small>
                                              Done
                                            </v-btn>
                                          </v-flex>
                                        </v-list>
                                      </v-menu>
                                      <h3 class="headline mb-0 font-weight-bold w-100">
                                        <v-text-field
                                          maxlength="300"
                                          solo
                                          v-model="c.text"
                                          flat
                                          @click.stop
                                          name="cardTitle"
                                          label="Enter your message here"
                                        ></v-text-field>
                                      </h3>
                                      <div>
                                        <v-textarea
                                          maxlength="500"
                                          flat
                                          rows="2"
                                          solo
                                          v-model="c.description"
                                          name="cardDescription"
                                          label="Enter card description here"
                                        ></v-textarea>
                                      </div>
  
                                      <v-card-actions>
                                        <div class="text-left">
                                          <v-menu v-for="(r, ri) in c.quickReplies" :key="ri"
                                            absolute
                                            offset-y
                                            class="mb-1"
                                            style="width: 20em;"
                                          >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="#0ab39c" block outlined slot="activator" v-bind="attrs" v-on="on">
                                              {{r.title}}
                                            </v-btn>
                                          </template>
                                            <v-list class="pa-3">
                                              <v-text-field
                                                class="grey--text text--lighten-2"
                                                maxlength="50"
                                                outlined
                                                v-model="r.title"
                                                flat
                                                @click.stop
                                                name="quickReply"
                                                :label="`Rating ${ri+1}`"
                                              ></v-text-field>
  
                                              <v-text-field
                                                class="grey--text text--lighten-2"
                                                maxlength="300"
                                                outlined
                                                v-model="r.postback"
                                                flat
                                                @click.stop
                                                name="postback"
                                                label="Amara's response"
                                              ></v-text-field>
  
                                              <v-flex xs12 class="text-right">
                                                <v-btn color="error" small text
                                                @click="deleteReply(qi, ci, ri)">Delete</v-btn>
                                                <v-btn color="#0ab39c" class="white--text" small>
                                                  Done
                                                </v-btn>
                                              </v-flex>
                                            </v-list>
                                          </v-menu>
  
                                          <p class="cursor-pointer mb-0 text-sm-center"
                                          v-if="c.quickReplies.length < 5"
                                          style="width: 20em;" @click="addReplies(qi, ci, 'Button')">
                                            <v-btn color="accent" class="mr-2" small outlined icon>
                                              <v-icon small>fa-regular fa-plus</v-icon>
                                            </v-btn>Add reply
                                          </p>
                                        </div>
                                      </v-card-actions>
                                    </v-card>
                                    <v-card>
                                      <p class="cursor-pointer mb-0 text-sm-center"
                                      v-if="q.msg_tl.length < 5"
                                      style="width: 20em;" @click="addCard(qi)">
                                        <v-btn color="accent" class="mr-2" small outlined icon>
                                          <v-icon small>fa-regular fa-plus</v-icon>
                                        </v-btn>Add card
                                      </p>
                                    </v-card>
                                  </div>
                                </v-flex>
  
                                <v-flex  xs10 offset-xs1
                                v-if="q.type === 'quickReplies' || q.type === 'closeEnded'">
                                  <v-text-field
                                    v-if="q.msg_tl"
                                    maxlength="300"
                                    class="elevation-0 grey--text text--lighten-2"
                                    v-model="q.msg_tl[0].text"
                                    :name="`interactionText`"
                                    :error-messages="errors.collect('interactionText')"
                                    label="Question"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    single-line
                                    outlined
                                  >
                                    <v-fade-transition slot="prepend">
                                      <img width="30" height="30"
                                      :src="getImgUrl('amara-60')" alt="Image">
                                    </v-fade-transition>
                                  </v-text-field>
  
                                  <div
                                    class="d-flex flex-row flex-wrap text-left justify-center" v-if="q.msg_tl"
                                  >
                                    <v-flex xs10 class="mb-3 text-center"
                                      v-for="(c, ci) in q.msg_tl[0].quickReplies" :key="ci">
                                      <div class="d-flex flex-row">
                                        <v-flex xs3>
                                          <v-btn color="#0ab39c" outlined slot="activator">
                                          {{c.title}}</v-btn>
                                        </v-flex>
                                        <v-flex xs9>
                                          <v-text-field class="grey--text text--lighten-2
                                          template-custom-field no-margin mb-2"
                                            maxlength="50"
                                            outlined
                                            v-model="c.title"
                                            flat
                                            @click.stop
                                            name="quickReply"
                                            :label="`Rating ${ci+1}`"
                                            v-validate="'required'"
                                          ></v-text-field>
  
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            maxlength="300"
                                            outlined
                                            v-model="c.postback"
                                            flat
                                            @click.stop
                                            name="postback"
                                            v-validate="'required'"
                                            label="Amara's response"
                                          ></v-text-field>
                                        </v-flex>
                                      </div>
                                    </v-flex>
  
                                    <span class="cursor-pointer" @click="addReplies(qi, 0, 'Answer')"
                                    v-if="q.msg_tl && q.msg_tl[0].quickReplies.length < 5 &&
                                    q.type === 'quickReplies'">
                                      <v-btn color="accent" small outlined icon>
                                        <v-icon small>fa-regular fa-plus</v-icon>
                                      </v-btn>Add reply
                                    </span>
                                  </div>
                                </v-flex>
  
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </div>
                    </v-flex>
  
                    <v-flex xs1></v-flex>
  
                  </div>
                  <div v-if="editLang && lang.language_code === 'ms'" class="d-flex flex-row flex-wrap grey lighten-5 drag-container" style="height:100%">
                    <v-flex xs1></v-flex>
                    <v-flex xs10>
                      <h3 class="ml-4 mt-3" v-if="selectedQuesType && selectedQuesType[0]">
                        {{selectedQuesType[0].title}}
                      </h3>
                      <div v-for="(q, qi) in selectedQuesType" :key="qi" v-show="qi === '0'">
                        <div class="flex-row d-flex draggable-item" style="max-height:350px;">
                          <v-flex class="ma-4 action-card text-center" sm12>
                            <div class="title text-capitalize d-flex flex-column">
                              {{q.title}}
                            </div>
                            <v-card class="mb-1 elevation-0 " >
  
                              <div class="pt-4 pa-3 d-flex flex-row flex-wrap align-center">
  
                                <!-- Scale -->
                                <v-flex  xs10 offset-xs1 v-if="q.type === 'scale'">
                                  <v-text-field
                                    v-if="q.msg_ms"
                                    maxlength="300"
                                    v-model="q.msg_ms[0].text"
                                    class="elevation-0 grey--text text--lighten-2"
                                    name="interactionText"
                                    :error-messages="errors.collect('interactionText')"
                                    label="Question"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    outlined
                                  >
                                  </v-text-field>
  
                                  <div class="d-flex flex-row flex-wrap justify-center"
                                  v-if="q.msg_ms && q.msg_ms[0].scaleType === 'emoji'">
                                    <v-flex xs10 class="mb-3 text-center"
                                    v-for="(r, ri) in q.msg_ms[0].scale" :key="ri">
                                      <div class="d-flex flex-row">
                                        <v-flex xs3>
                                          <v-btn class="emoji-btn"
                                            slot="activator"
                                            fab text rounded
                                          >
                                            <img v-if="r.img" :src="getImgUrl(r.img)"
                                            class="emoji-container" alt="Image"/>
                                          </v-btn>
                                          <p class="text-center mt-2">
                                            {{r.title}}
                                          </p>
                                        </v-flex>
  
                                        <v-flex xs9>
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            style="position:relative;top:-10px"
                                            maxlength="50"
                                            v-model="r.title"
                                            flat
                                            outline
                                            @click.stop
                                            name="quickReply"
                                            :label="`Rating ${ri+1}`"
                                          ></v-text-field>
  
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            style="position:relative;top:-10px"
                                            maxlength="300"
                                            v-model="r.postback"
                                            flat
                                            outline
                                            @click.stop
                                            name="postback"
                                            label="Amara's response"
                                          ></v-text-field>
                                        </v-flex>
  
                                      </div>
  
                                    </v-flex>
                                  </div>
  
                                  <div class="d-flex flex-row flex-wrap justify-center"
                                  v-if="q.msg_ms && q.msg_ms[0].scaleType === 'number'">
                                    <v-flex xs10 class="mb-3 text-center"
                                    v-for="(r, ri) in q.msg_ms[0].scale" :key="ri">
                                      <div class="d-flex flex-row">
                                        <v-flex xs3>
                                          <v-btn class="emoji-btn"
                                            :color="r.color"
                                            fab
                                            outlined
                                            rounded
                                            slot="activator"
                                          >
                                            <strong class="headline">
                                              {{r.value}}
                                            </strong>
                                          </v-btn>
  
                                          <p class="text-center">
                                            {{r.title}}
                                          </p>
                                        </v-flex>
  
                                        <v-flex xs9>
                                          <v-text-field class="grey--text text--lighten-2
                                          template-custom-field no-margin mb-2"
                                            maxlength="50"
                                            outline
                                            v-model="r.title"
                                            flat
                                            @click.stop
                                            name="quickReply"
                                            :label="`Rating ${ri+1}`"
                                          ></v-text-field>
  
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            maxlength="300"
                                            outline
                                            v-model="r.postback"
                                            flat
                                            @click.stop
                                            name="postback"
                                            label="Amara's response"
                                          ></v-text-field>
                                        </v-flex>
                                      </div>
                                    </v-flex>
                                  </div>
                                </v-flex>
  
                                <v-flex  xs10 offset-xs1 v-if="q.type === 'textInput'">
                                  <v-text-field
                                    maxlength="300"
                                    v-model="q.msg_ms['0'].text"
                                    class="elevation-0 grey--text text--lighten-2"
                                    :name="`interactionText_${qi}`"
                                    :error-messages="errors.collect(`interactionText_${qi}`)"
                                    label="Question"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    single-line
                                    outline
                                  >
                                    <v-fade-transition slot="prepend">
                                      <img width="30" height="30"
                                      :src="getImgUrl('amara-60')" alt="Image">
                                    </v-fade-transition>
                                  </v-text-field>
                                </v-flex>
  
                                <!-- Text -->
                                <v-flex  xs10 offset-xs1 v-if="q.type === 'text'">
                                  <v-text-field
                                    maxlength="300"
                                    v-model="q.msg_ms['0'].text"
                                    class="elevation-0 grey--text text--lighten-2"
                                    :name="`interactionText_${qi}`"
                                    :error-messages="errors.collect(`interactionText_${qi}`)"
                                    label="What should Amara say?"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    single-line
                                    outlined
                                  >
                                    <v-fade-transition slot="prepend">
                                      <img width="30" height="30"
                                      :src="getImgUrl('amara-60')" alt="Image">
                                    </v-fade-transition>
                                  </v-text-field>
                                </v-flex>
  
                                <v-flex  v-if="q.type === 'image'">
                                  <v-menu
                                    absolute
                                    v-if="q.msg_ms"
                                    offset-y
                                    style="max-width: 600px"
                                  >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-img
                                      height="200"
                                      width="300"
                                      v-bind="attrs"
                                      v-on="on"
                                      slot="activator"
                                      max-height="200"
                                      max-width="300"
                                      :src="q.msg_ms[0].imgUrl"
                                      :lazy-src="q.msg_ms[0].imgUrl"
                                      aspect-ratio="1"
                                      class="grey lighten-2 mx-auto"
                                    >
                                      <div
                                        class="d-flex flex-row fill-height align-center justify-center ma-0"
                                        slot="placeholder"
                                      >
                                        <div >
                                          <v-icon class="mr-2">fa-regular fa-plus-square</v-icon>
                                          <strong class="mb-2">
                                            Add Image
                                          </strong>
                                        </div>
                                        <v-progress-circular v-show="q.msg_ms[0].imgUrl"
                                        indeterminate color="grey lighten-5">
                                        </v-progress-circular>
                                      </div>
                                    </v-img>
                                  </template>
                                    <v-list class="pa-3">
                                      <v-text-field
                                        maxlength="1000"
                                        outlined
                                        v-model="q.msg_ms[0].imgUrl"
                                        flat
                                        @click.stop
                                        name="url"
                                        label="Your image URL"
                                      ></v-text-field>
                                      <v-flex xs12 class="text-right">
                                        <v-btn color="#0ab39c" class="white--text" small>Done</v-btn>
                                      </v-flex>
                                    </v-list>
                                  </v-menu>
                                </v-flex>
  
                                <v-flex  v-if="q.type === 'card'">
                                  <v-card class="mx-auto" width="300"
                                    v-for="(c, ci) in q.msg_ms" :key="ci"
                                  >
                                    <v-menu
                                      absolute
                                      offset-y
                                      style="max-width: 600px"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-img
                                        height="200"
                                        width="300"
                                        slot="activator"
                                        max-height="200"
                                        max-width="300"
                                        v-bind="attrs"
                                        v-on="on"
                                        :src="c.imgUrl"
                                        :lazy-src="c.imgUrl"
                                        aspect-ratio="1"
                                        class="grey lighten-2 mx-auto"
                                      >
                                        <div class="d-flex flex-row fill-height align-center justify-center ma-0"
                                          slot="placeholder"
                                        >
                                          <div >
                                            <v-icon class="mr-2">fa-regular fa-plus-square</v-icon>
                                            <strong class="mb-2">
                                              Add Image
                                            </strong>
                                          </div>
                                          <v-progress-circular v-show="c.imgUrl"
                                          indeterminate color="grey lighten-5">
                                          </v-progress-circular>
                                        </div>
                                      </v-img>
                                    </template>
                                      <v-list class="pa-3">
                                        <v-text-field
                                          maxlength="1000"
                                          outlined
                                          v-model="c.imgUrl"
                                          flat
                                          @click.stop
                                          name="url"
                                          label="Your image URL"
                                        ></v-text-field>
                                        <v-flex xs12 class="text-right">
                                          <v-btn color="#0ab39c" class="white--text" small>
                                            Done
                                          </v-btn>
                                        </v-flex>
                                      </v-list>
                                    </v-menu>
                                    <h3 class="headline mb-0 font-weight-bold w-100">
                                      <v-text-field
                                        maxlength="300"
                                        solo
                                        v-model="c.text"
                                        flat
                                        @click.stop
                                        name="cardTitle"
                                        label="Enter your message here"
                                      ></v-text-field>
                                    </h3>
                                    <div>
                                      <v-textarea
                                        maxlength="500"
                                        flat
                                        rows="2"
                                        solo
                                        v-model="c.description"
                                        name="cardDescription"
                                        label="Enter card description here"
                                      ></v-textarea>
                                    </div>
  
                                    <v-card-actions>
                                      <div class="text-left">
                                        <v-menu v-for="(r, ri) in c.quickReplies" :key="ri"
                                          absolute
                                          offset-y
                                          class="mb-1"
                                          style="width: 20em;"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn color="#0ab39c" block outlined slot="activator" v-bind="attrs" v-on="on">
                                            {{r.title}}
                                          </v-btn>
                                        </template>
                                          <v-list class="pa-3">
                                            <v-text-field
                                              class="grey--text text--lighten-2"
                                              maxlength="50"
                                              outlined
                                              v-model="r.title"
                                              flat
                                              @click.stop
                                              name="quickReply"
                                              :label="`Rating ${ri+1}`"
                                            ></v-text-field>
  
                                            <v-text-field
                                              class="grey--text text--lighten-2"
                                              maxlength="300"
                                              outlined
                                              v-model="r.postback"
                                              flat
                                              @click.stop
                                              name="postback"
                                              label="Amara's response"
                                            ></v-text-field>
  
                                            <v-flex xs12 class="text-right">
                                              <v-btn color="error" small text
                                              @click="deleteReply(qi, ci, ri)">Delete</v-btn>
                                              <v-btn color="#0ab39c" class="white--text" small>
                                                Done
                                              </v-btn>
                                            </v-flex>
                                          </v-list>
                                        </v-menu>
  
                                        <p class="cursor-pointer mb-0 text-sm-center"
                                        v-if="c.quickReplies.length < 5"
                                        style="width: 20em;" @click="addReplies(qi, ci, 'Button')">
                                          <v-btn color="accent" class="mr-2" small outlined icon>
                                            <v-icon small>fa-regular fa-plus</v-icon>
                                          </v-btn>Add reply
                                        </p>
                                      </div>
                                    </v-card-actions>
                                  </v-card>
                                </v-flex>
  
                                <v-flex  v-if="q.type === 'carousel'">
                                  <div class="d-flex flex-wrap flex-row">
                                    <v-card class="mr-3" width="300"
                                      v-for="(c, ci) in q.msg_ms" :key="ci"
                                    >
                                      <v-menu
                                        absolute
                                        offset-y
                                        style="max-width: 600px"
                                      >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-img
                                          height="200"
                                          width="300"
                                          slot="activator"
                                          v-bind="attrs"
                                          v-on="on"
                                          max-height="200"
                                          max-width="300"
                                          :src="c.imgUrl"
                                          :lazy-src="c.imgUrl"
                                          aspect-ratio="1"
                                          class="grey lighten-2 mx-auto"
                                        >
                                          <div
                                            class="d-flex flex-row fill-height align-center justify-center ma-0"
                                            slot="placeholder"
                                          >
                                            <div >
                                              <v-icon class="mr-2">fa-regular fa-plus-square</v-icon>
                                              <strong class="mb-2">
                                                Add Image
                                              </strong>
                                            </div>
                                            <v-progress-circular v-show="c.imgUrl"
                                            indeterminate color="grey lighten-5">
                                            </v-progress-circular>
                                          </div>
                                        </v-img>
                                      </template>
                                        <v-list class="pa-3">
                                          <v-text-field
                                            maxlength="1000"
                                            outlined
                                            v-model="c.imgUrl"
                                            flat
                                            @click.stop
                                            name="url"
                                            label="Your image URL"
                                          ></v-text-field>
                                          <v-flex xs12 class="text-right">
                                            <v-btn color="#0ab39c" class="white--text" small>
                                              Done
                                            </v-btn>
                                          </v-flex>
                                        </v-list>
                                      </v-menu>
                                      <h3 class="headline mb-0 font-weight-bold w-100">
                                        <v-text-field
                                          maxlength="300"
                                          solo
                                          v-model="c.text"
                                          flat
                                          @click.stop
                                          name="cardTitle"
                                          label="Enter your message here"
                                        ></v-text-field>
                                      </h3>
                                      <div>
                                        <v-textarea
                                          maxlength="500"
                                          flat
                                          rows="2"
                                          solo
                                          v-model="c.description"
                                          name="cardDescription"
                                          label="Enter card description here"
                                        ></v-textarea>
                                      </div>
  
                                      <v-card-actions>
                                        <div class="text-left">
                                          <v-menu v-for="(r, ri) in c.quickReplies" :key="ri"
                                            absolute
                                            offset-y
                                            class="mb-1"
                                            style="width: 20em;"
                                          >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="#0ab39c" block outlined slot="activator" v-bind="attrs" v-on="on">
                                              {{r.title}}
                                            </v-btn>
                                          </template>
                                            <v-list class="pa-3">
                                              <v-text-field
                                                class="grey--text text--lighten-2"
                                                maxlength="50"
                                                outlined
                                                v-model="r.title"
                                                flat
                                                @click.stop
                                                name="quickReply"
                                                :label="`Rating ${ri+1}`"
                                              ></v-text-field>
  
                                              <v-text-field
                                                class="grey--text text--lighten-2"
                                                maxlength="300"
                                                outlined
                                                v-model="r.postback"
                                                flat
                                                @click.stop
                                                name="postback"
                                                label="Amara's response"
                                              ></v-text-field>
  
                                              <v-flex xs12 class="text-right">
                                                <v-btn color="error" small text
                                                @click="deleteReply(qi, ci, ri)">Delete</v-btn>
                                                <v-btn color="#0ab39c" class="white--text" small>
                                                  Done
                                                </v-btn>
                                              </v-flex>
                                            </v-list>
                                          </v-menu>
  
                                          <p class="cursor-pointer mb-0 text-sm-center"
                                          v-if="c.quickReplies.length < 5"
                                          style="width: 20em;" @click="addReplies(qi, ci, 'Button')">
                                            <v-btn color="accent" class="mr-2" small outlined icon>
                                              <v-icon small>fa-regular fa-plus</v-icon>
                                            </v-btn>Add reply
                                          </p>
                                        </div>
                                      </v-card-actions>
                                    </v-card>
                                    <v-card>
                                      <p class="cursor-pointer mb-0 text-sm-center"
                                      v-if="q.msg_ms.length < 5"
                                      style="width: 20em;" @click="addCard(qi)">
                                        <v-btn color="accent" class="mr-2" small outlined icon>
                                          <v-icon small>fa-regular fa-plus</v-icon>
                                        </v-btn>Add card
                                      </p>
                                    </v-card>
                                  </div>
                                </v-flex>
  
                                <v-flex  xs10 offset-xs1
                                v-if="q.type === 'quickReplies' || q.type === 'closeEnded'">
                                  <v-text-field
                                    v-if="q.msg_ms"
                                    maxlength="300"
                                    class="elevation-0 grey--text text--lighten-2"
                                    v-model="q.msg_ms[0].text"
                                    :name="`interactionText`"
                                    :error-messages="errors.collect('interactionText')"
                                    label="Question"
                                    autofocus
                                    data-vv-as="interaction text"
                                    v-validate="'required'"
                                    single-line
                                    outlined
                                  >
                                    <v-fade-transition slot="prepend">
                                      <img width="30" height="30"
                                      :src="getImgUrl('amara-60')" alt="Image">
                                    </v-fade-transition>
                                  </v-text-field>
  
                                  <div
                                    class="d-flex flex-row flex-wrap text-left justify-center" v-if="q.msg_ms"
                                  >
                                    <v-flex xs10 class="mb-3 text-center"
                                      v-for="(c, ci) in q.msg_ms[0].quickReplies" :key="ci">
                                      <div class="d-flex flex-row">
                                        <v-flex xs3>
                                          <v-btn color="#0ab39c" outlined slot="activator">
                                          {{c.title}}</v-btn>
                                        </v-flex>
                                        <v-flex xs9>
                                          <v-text-field class="grey--text text--lighten-2
                                          template-custom-field no-margin mb-2"
                                            maxlength="50"
                                            outlined
                                            v-model="c.title"
                                            flat
                                            @click.stop
                                            name="quickReply"
                                            :label="`Rating ${ci+1}`"
                                            v-validate="'required'"
                                            :error-messages="errors.collect('quickReply')"
                                            hide-details
                                          ></v-text-field>
  
                                          <v-text-field class="grey--text text--lighten-2
                                            template-custom-field no-margin mb-2"
                                            maxlength="300"
                                            outlined
                                            v-model="c.postback"
                                            flat
                                            @click.stop
                                            name="postback"
                                            label="Amara's response"
                                            v-validate="'required'"
                                            :error-messages="errors.collect('postback')"
                                            hide-details
                                          ></v-text-field>
                                        </v-flex>
                                      </div>
                                    </v-flex>
  
                                    <span class="cursor-pointer" @click="addReplies(qi, 0, 'Answer')"
                                    v-if="q.msg_ms[0]&& q.msg_ms[0].quickReplies.length < 5 &&
                                    q.type === 'quickReplies'">
                                      <v-btn color="accent" small outlined icon>
                                        <v-icon small>fa-regular fa-plus</v-icon>
                                      </v-btn>Add reply
                                    </span>
                                  </div>
                                </v-flex>
  
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </div>
                    </v-flex>
  
                    <v-flex xs1></v-flex>
                  </div>
  
                </v-card>
              </v-flex>
  
            </div>
          </v-card-text>
  
          <v-card-actions class="mt-3" align-content-space->
            <v-flex xs2>
              <v-btn text @click.stop="dialogs.newQuestion=false;clearValidation();$parent.$parent.getSatgeDetails($parent.stageData.id, $parent.$parent.selectedStage);">Cancel</v-btn>
            </v-flex>
            <v-flex xs8>
            </v-flex>
            <v-flex xs2 v-if="!editFlag && !editMLFlag" class="text-right">
              <v-btn color="primary" class="white--text" @click.stop="validateDetails()"
              v-if="selectedQuesType && selectedQuesType[0] &&
              selectedQuesType[0].type === 'text'"> Add Statement </v-btn>
              <v-btn color="primary" class="white--text" @click.stop="validateDetails()" v-else>
                Save Question
              </v-btn>
            </v-flex>
            <v-flex xs2 v-else class="text-right">
              <v-btn color="primary" class="white--text" @click.stop="validateDetails()"
              v-if="selectedQuesType && selectedQuesType[0] && selectedQuesType[0].type === 'text'">
                Update Statement
              </v-btn>
              <v-btn color="primary" class="white--text" @click.stop="validateDetails()" v-if="!lang && (!selectedQuesType || !selectedQuesType[0] ||
            selectedQuesType[0].type !== 'text')">
              Save Question
              </v-btn>
              <v-btn color="primary" class="white--text" @click.stop="validateDetails()" v-if="lang && !this.editMLFlag">
                Save Question
              </v-btn>
              <v-btn color="primary" class="white--text" @click.stop="validateDetails()" v-if="lang && this.editMLFlag">
                Save Question
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </template>
  
  <script>
  /* eslint-disable max-len */
  /* eslint-disable max-len */
  /* eslint-disable max-len */
  import axios from 'axios';
import { mapState } from 'vuex';
  
  export default {
    name: 'NewQuestion',
    components: {
    },
    data () {
      return {
        checkDriver: '',
        checkSubDriver: '',
        dialogs: {
          newQuestion: false,
          questionBank: false,
          campaigns: false
        },
        lang: null,
        editLang: false,
        selectedQuesType: {},
        config: {
          initialLoading: false
        },
        editFlag: false,
        editMLFlag: false,
        questionIndex: null,
        showMenu: false,
        ifSmall: false,
        drivers: [],
        subDrivers: [],
        roleList: [],
        newSurvey: {
          title: '',
          description: '',
          questions: [],
          type: ''
        }
      };
    },
    computed: {
      ...mapState({
        user: state => state.user
      })
    },
    watch: {
      lang () {
        if (this.lang) {
          this.$lodash.each(this.selectedQuesType, (q) => {
            this.$lodash.each(q.msg, (t) => {
              t.text = '';
              this.$lodash.each(t.scale, (s) => {
                s.postback = '';
                s.title = '';
              });
            });
          });
        }
      }
    },
    methods: {
      clearValidation () {
        this.$validator.reset();
      },
      getRoles () {
        axios.get(`${process.env.VUE_APP_API_URL_FB}driver/framework`).then((response) => {
          if (response && response.data) {
            this.$nextTick(function a () {
              this.roleList = response.data;
              this.$lodash.each(this.roleList, (subDrivers, driver) => {
                this.drivers.push({
                  name: driver.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
                  value: driver
                });
              });
              this.$forceUpdate();
              if (this.selectedQuesType && this.selectedQuesType[0]) {
                const tempdriver = this.selectedQuesType[0].driver;
                const temp = this.roleList[tempdriver];
                this.$lodash.each(temp, (subDriver) => {
                  this.subDrivers.push({
                    name: subDriver.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
                    value: subDriver
                  });
                });
              }
            });
          }
        }, (response) => {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to fetch roles, Please try again later!'
          });
          throw new Error(response);
        });
      },
      getSubDrivers (selectedDriver) {
        this.$lodash.each(this.roleList, (subDrivers, driver) => {
          if (selectedDriver === driver) {
            this.subDrivers = [];
            this.$lodash.each(subDrivers, (subDriver) => {
              this.subDrivers.push({
                name: subDriver.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
                value: subDriver
              });
            });
          }
        });
      },
      getRoleName (role) {
        let roleName = '';
        roleName = role.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
        return roleName;
      },
      getImgUrl (pet) {
        const images = require.context('@/assets/', false, /\.png$/);
        return images(`./${pet}.png`);
      },
      deleteReply (index, qi, oi) {
        if (this.newSurvey.questions[index].msg[qi].quickReplies &&
        this.newSurvey.questions[index].msg[qi].quickReplies.length > 1) {
          this.newSurvey.questions[index].msg[qi].quickReplies.splice(oi, 1);
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'info',
            show: true,
            text: 'At least one reply is required!'
          });
        }
      },
      addCard (index) {
        this.newSurvey.questions[index].msg.push({
          imgUrl: '',
          text: '',
          description: '',
          quickReplies: [
            {
              title: 'Yes',
              postback: 'Good to know!'
            }, {
              title: 'No',
              postback: 'That\'s sad'
            }
          ]
        });
      },
      addReplies (index, qi, text) {
        this.selectedQuesType.msg[qi].quickReplies.push({
          title: `${text} #${this.selectedQuesType.msg[qi].quickReplies.length + 1}`
        });
      },
      validateDetails () {
        if (!this.selectedQuesType[0].driver) {
          this.selectedQuesType[0].driver = ' ';
        }
        if (!this.selectedQuesType[0].sub_driver) {
          this.selectedQuesType[0].sub_driver = ' ';
        }
        if ((this.checkDriver && this.checkSubDriver) || this.selectedQuesType[0].driver === 'Mood' || this.selectedQuesType[0].sub_driver === 'Mood' || (this.selectedQuesType[0].driver.length > 1 && this.selectedQuesType[0].sub_driver.length > 1)) {
          this.$validator.validateAll().then((res) => {
            if (res) {
              // console.log(this.selectedQuesType);
              if (this.selectedQuesType && this.selectedQuesType[0] && (this.selectedQuesType[0].type !== 'textInput' || this.selectedQuesType[0].type !== 'text')) {
                if (this.selectedQuesType[0]) {
                  console.log(this.questionIndex);
                  if ((this.questionIndex || this.questionIndex === 0) && !this.editMLFlag) {
                    this.addQuestionInLang();
                  } else if (this.editFlag && (this.questionIndex || this.questionIndex === 0) && this.editMLFlag) {
                    this.updateQuestion();
                  } else if (this.editFlag && (this.questionIndex || this.questionIndex === 0) && !this.editMLFlag) {
                    this.updateQuestion();
                  } else if ((this.questionIndex || this.questionIndex === 0) && this.editMLFlag) {
                    this.updateMLQuestion();
                  } else {
                    this.addQuestion();
                  }
                  setTimeout(() => {
                    this.updateTouchpointQuestion();
                    this.$parent.questionTypes = [{
                      delay: 2000,
                      title: 'Open ended question',
                      description: 'This require respondents to type their answer into a comment box',
                      type: 'textInput',
                      img: 'fa-regular fa-keyboard',
                      msg: [{
                        text: ''
                      }],
                      responseRequired: true,
                      response: []
                    }],
                    this.$parent.questionTypesD = [{
                      delay: 2000,
                      title: 'Rating scale',
                      description: 'The respondent selects the number that most accurately represents their response',
                      type: 'scale',
                      role: '',
                      img: 'fa-regular fa-arrows-alt-h',
                      msg: [{
                        text: '',
                        scaleType: 'number',
                        scale: [
                          {
                            title: 'Awful',
                            color: 'red',
                            value: 1,
                            postback: 'I am really sorry to hear that'
                          }, {
                            title: 'Not very good',
                            color: 'deep-orange',
                            value: 2,
                            postback: 'That\'s not good'
                          }, {
                            title: 'Good',
                            color: 'orange',
                            value: 3,
                            postback: 'I think we can improve'
                          }, {
                            title: 'Really good',
                            color: 'light-green',
                            value: 4,
                            postback: 'That\'s good to know'
                          }, {
                            title: 'Brilliant',
                            color: 'green',
                            value: 5,
                            postback: 'I am happy to know'
                          }
                        ]
                      }],
                      responseRequired: true,
                      response: []
                    }, {
                      delay: 2000,
                      title: 'Yes/No',
                      description: 'Used where ambiguity is the enemy',
                      type: 'closeEnded',
                      msg: [{
                        text: '',
                        quickReplies: [
                          {
                            title: 'Yes',
                            value: 5,
                            postback: 'Good to know!'
                          }, {
                            title: 'No',
                            value: 1,
                            postback: 'That\'s sad'
                          }
                        ]
                      }],
                      img: 'fa-regular fa-reply-all',
                      responseRequired: true,
                      response: []
                    }],
                    this.$parent.config =  {
                      emojiScale: {
                        delay: 2000,
                        title: 'Emoji Scale',
                        description: 'Used to determine how someone feels about your company',
                        type: 'scale',
                        role: 'mood',
                        img: 'fa-regular fa-smile',
                        msg: [{
                          text: '',
                          scaleType: 'emoji',
                          scale: [
                            {
                              title: 'Terrible',
                              img: 'crying',
                              value: 1,
                              postback: `I am very sorry to hear that 😞`
                            }, {
                              title: 'Disappointed',
                              img: 'sad',
                              value: 2,
                              postback: 'Ah! That\'s bad.'
                            }, {
                              title: 'Okay',
                              img: 'shocked',
                              value: 3,
                              postback: 'Hmmmmm alright!'
                            }, {
                              title: 'Good',
                              img: 'happy',
                              value: 4,
                              postback: 'That\'s nice!'
                            }, {
                              title: 'Awesome',
                              img: 'in-love',
                              value: 5,
                              postback: 'I am so glad to know that 🙂'
                            }
                          ]
                        }],
                        responseRequired: true,
                        response: []
                      },
                    }
                    console.log('tyhuu', this.$parent.questionTypes);
                  }, 1000);
                } else {
                  this.$store.dispatch('updateSnackbar', {
                    color: 'error',
                    show: true,
                    text: 'Fill all the mandatory fields!'
                  });
                }
              } else {
                if (this.editFlag && (this.questionIndex || this.questionIndex === 0)) {
                  this.updateQuestion();
                } else {
                  this.addQuestion();
                }
                setTimeout(() => {
                    this.updateTouchpointQuestion();
                    this.$parent.questionTypes = [{
                      delay: 2000,
                      title: 'Open ended question',
                      description: 'This require respondents to type their answer into a comment box',
                      type: 'textInput',
                      img: 'fa-regular fa-keyboard',
                      msg: [{
                        text: ''
                      }],
                      responseRequired: true,
                      response: []
                    }],
                    this.$parent.questionTypesD = [{
                      delay: 2000,
                      title: 'Rating scale',
                      description: 'The respondent selects the number that most accurately represents their response',
                      type: 'scale',
                      role: '',
                      img: 'fa-regular fa-arrows-alt-h',
                      msg: [{
                        text: '',
                        scaleType: 'number',
                        scale: [
                          {
                            title: 'Awful',
                            color: 'red',
                            value: 1,
                            postback: 'I am really sorry to hear that'
                          }, {
                            title: 'Not very good',
                            color: 'deep-orange',
                            value: 2,
                            postback: 'That\'s not good'
                          }, {
                            title: 'Good',
                            color: 'orange',
                            value: 3,
                            postback: 'I think we can improve'
                          }, {
                            title: 'Really good',
                            color: 'light-green',
                            value: 4,
                            postback: 'That\'s good to know'
                          }, {
                            title: 'Brilliant',
                            color: 'green',
                            value: 5,
                            postback: 'I am happy to know'
                          }
                        ]
                      }],
                      responseRequired: true,
                      response: []
                    }, {
                      delay: 2000,
                      title: 'Yes/No',
                      description: 'Used where ambiguity is the enemy',
                      type: 'closeEnded',
                      msg: [{
                        text: '',
                        quickReplies: [
                          {
                            title: 'Yes',
                            value: 5,
                            postback: 'Good to know!'
                          }, {
                            title: 'No',
                            value: 1,
                            postback: 'That\'s sad'
                          }
                        ]
                      }],
                      img: 'fa-regular fa-reply-all',
                      responseRequired: true,
                      response: []
                    }],
                    this.$parent.config =  {
                      emojiScale: {
                        delay: 2000,
                        title: 'Emoji Scale',
                        description: 'Used to determine how someone feels about your company',
                        type: 'scale',
                        role: 'mood',
                        img: 'fa-regular fa-smile',
                        msg: [{
                          text: '',
                          scaleType: 'emoji',
                          scale: [
                            {
                              title: 'Terrible',
                              img: 'crying',
                              value: 1,
                              postback: `I am very sorry to hear that 😞`
                            }, {
                              title: 'Disappointed',
                              img: 'sad',
                              value: 2,
                              postback: 'Ah! That\'s bad.'
                            }, {
                              title: 'Okay',
                              img: 'shocked',
                              value: 3,
                              postback: 'Hmmmmm alright!'
                            }, {
                              title: 'Good',
                              img: 'happy',
                              value: 4,
                              postback: 'That\'s nice!'
                            }, {
                              title: 'Awesome',
                              img: 'in-love',
                              value: 5,
                              postback: 'I am so glad to know that 🙂'
                            }
                          ]
                        }],
                        responseRequired: true,
                        response: []
                      },
                    }
                    console.log('tyhuu', this.$parent.questionTypesD);
                }, 1000);
              }
            } else {
              this.$store.dispatch('updateSnackbar', {
                color: 'error',
                show: true,
                text: 'Fill all the mandatory fields!'
              });
            }
          });
        // eslint-disable-next-line eqeqeq
        } else if (this.selectedQuesType[0].type == 'textInput' || this.selectedQuesType[0].type == 'text') {
          this.$validator.validateAll().then((res) => {
            if (res) {
              // console.log(this.selectedQuesType);
              if (this.selectedQuesType && this.selectedQuesType[0] && (this.selectedQuesType[0].type !== 'textInput' || this.selectedQuesType[0].type !== 'text')) {
                if (this.selectedQuesType[0]) {
                  console.log(this.questionIndex);
                  if ((this.questionIndex || this.questionIndex === 0) && !this.editMLFlag) {
                    this.addQuestionInLang();
                  } else if (this.editFlag && (this.questionIndex || this.questionIndex === 0) && this.editMLFlag) {
                    this.updateQuestion();
                  } else if (this.editFlag && (this.questionIndex || this.questionIndex === 0) && !this.editMLFlag) {
                    this.updateQuestion();
                  } else if ((this.questionIndex || this.questionIndex === 0) && this.editMLFlag) {
                    this.updateMLQuestion();
                  } else {
                    this.addQuestion();
                  }
                  setTimeout(() => {
                      this.updateTouchpointQuestion();
                      this.$parent.questionTypes = [{
                      delay: 2000,
                      title: 'Open ended question',
                      description: 'This require respondents to type their answer into a comment box',
                      type: 'textInput',
                      img: 'fa-regular fa-keyboard',
                      msg: [{
                        text: ''
                      }],
                      responseRequired: true,
                      response: []
                    }],
                    this.$parent.questionTypesD = [{
                      delay: 2000,
                      title: 'Rating scale',
                      description: 'The respondent selects the number that most accurately represents their response',
                      type: 'scale',
                      role: '',
                      img: 'fa-regular fa-arrows-alt-h',
                      msg: [{
                        text: '',
                        scaleType: 'number',
                        scale: [
                          {
                            title: 'Awful',
                            color: 'red',
                            value: 1,
                            postback: 'I am really sorry to hear that'
                          }, {
                            title: 'Not very good',
                            color: 'deep-orange',
                            value: 2,
                            postback: 'That\'s not good'
                          }, {
                            title: 'Good',
                            color: 'orange',
                            value: 3,
                            postback: 'I think we can improve'
                          }, {
                            title: 'Really good',
                            color: 'light-green',
                            value: 4,
                            postback: 'That\'s good to know'
                          }, {
                            title: 'Brilliant',
                            color: 'green',
                            value: 5,
                            postback: 'I am happy to know'
                          }
                        ]
                      }],
                      responseRequired: true,
                      response: []
                    }, {
                      delay: 2000,
                      title: 'Yes/No',
                      description: 'Used where ambiguity is the enemy',
                      type: 'closeEnded',
                      msg: [{
                        text: '',
                        quickReplies: [
                          {
                            title: 'Yes',
                            value: 5,
                            postback: 'Good to know!'
                          }, {
                            title: 'No',
                            value: 1,
                            postback: 'That\'s sad'
                          }
                        ]
                      }],
                      img: 'fa-regular fa-reply-all',
                      responseRequired: true,
                      response: []
                    }],
                    this.$parent.config =  {
                      emojiScale: {
                        delay: 2000,
                        title: 'Emoji Scale',
                        description: 'Used to determine how someone feels about your company',
                        type: 'scale',
                        role: 'mood',
                        img: 'fa-regular fa-smile',
                        msg: [{
                          text: '',
                          scaleType: 'emoji',
                          scale: [
                            {
                              title: 'Terrible',
                              img: 'crying',
                              value: 1,
                              postback: `I am very sorry to hear that 😞`
                            }, {
                              title: 'Disappointed',
                              img: 'sad',
                              value: 2,
                              postback: 'Ah! That\'s bad.'
                            }, {
                              title: 'Okay',
                              img: 'shocked',
                              value: 3,
                              postback: 'Hmmmmm alright!'
                            }, {
                              title: 'Good',
                              img: 'happy',
                              value: 4,
                              postback: 'That\'s nice!'
                            }, {
                              title: 'Awesome',
                              img: 'in-love',
                              value: 5,
                              postback: 'I am so glad to know that 🙂'
                            }
                          ]
                        }],
                        responseRequired: true,
                        response: []
                      },
                    }
                    console.log('tyhuu', this.$parent.questionTypes);
                  }, 1000);
                } else {
                  this.$store.dispatch('updateSnackbar', {
                    color: 'error',
                    show: true,
                    text: 'Fill all the mandatory fields!'
                  });
                }
              } else {
                if (this.editFlag && (this.questionIndex || this.questionIndex === 0)) {
                  this.updateQuestion();
                } else {
                  this.addQuestion();
                }
                setTimeout(() => {
                    this.updateTouchpointQuestion();
                    this.$parent.questionTypes = [{
                      delay: 2000,
                      title: 'Open ended question',
                      description: 'This require respondents to type their answer into a comment box',
                      type: 'textInput',
                      img: 'fa-regular fa-keyboard',
                      msg: [{
                        text: ''
                      }],
                      responseRequired: true,
                      response: []
                    }],
                    this.$parent.questionTypesD = [{
                      delay: 2000,
                      title: 'Rating scale',
                      description: 'The respondent selects the number that most accurately represents their response',
                      type: 'scale',
                      role: '',
                      img: 'fa-regular fa-arrows-alt-h',
                      msg: [{
                        text: '',
                        scaleType: 'number',
                        scale: [
                          {
                            title: 'Awful',
                            color: 'red',
                            value: 1,
                            postback: 'I am really sorry to hear that'
                          }, {
                            title: 'Not very good',
                            color: 'deep-orange',
                            value: 2,
                            postback: 'That\'s not good'
                          }, {
                            title: 'Good',
                            color: 'orange',
                            value: 3,
                            postback: 'I think we can improve'
                          }, {
                            title: 'Really good',
                            color: 'light-green',
                            value: 4,
                            postback: 'That\'s good to know'
                          }, {
                            title: 'Brilliant',
                            color: 'green',
                            value: 5,
                            postback: 'I am happy to know'
                          }
                        ]
                      }],
                      responseRequired: true,
                      response: []
                    }, {
                      delay: 2000,
                      title: 'Yes/No',
                      description: 'Used where ambiguity is the enemy',
                      type: 'closeEnded',
                      msg: [{
                        text: '',
                        quickReplies: [
                          {
                            title: 'Yes',
                            value: 5,
                            postback: 'Good to know!'
                          }, {
                            title: 'No',
                            value: 1,
                            postback: 'That\'s sad'
                          }
                        ]
                      }],
                      img: 'fa-regular fa-reply-all',
                      responseRequired: true,
                      response: []
                    }],
                    this.$parent.config =  {
                      emojiScale: {
                        delay: 2000,
                        title: 'Emoji Scale',
                        description: 'Used to determine how someone feels about your company',
                        type: 'scale',
                        role: 'mood',
                        img: 'fa-regular fa-smile',
                        msg: [{
                          text: '',
                          scaleType: 'emoji',
                          scale: [
                            {
                              title: 'Terrible',
                              img: 'crying',
                              value: 1,
                              postback: `I am very sorry to hear that 😞`
                            }, {
                              title: 'Disappointed',
                              img: 'sad',
                              value: 2,
                              postback: 'Ah! That\'s bad.'
                            }, {
                              title: 'Okay',
                              img: 'shocked',
                              value: 3,
                              postback: 'Hmmmmm alright!'
                            }, {
                              title: 'Good',
                              img: 'happy',
                              value: 4,
                              postback: 'That\'s nice!'
                            }, {
                              title: 'Awesome',
                              img: 'in-love',
                              value: 5,
                              postback: 'I am so glad to know that 🙂'
                            }
                          ]
                        }],
                        responseRequired: true,
                        response: []
                      },
                    }
                    console.log('tyhuu', this.$parent.questionTypes);
                }, 1000);
              }
            } else {
              this.$store.dispatch('updateSnackbar', {
                color: 'error',
                show: true,
                text: 'Fill all the mandatory fields!'
              });
            }
          });
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Map driver and subdriver first!'
          });
        }
        
      },
      updateTouchpointQuestion () {
      if (this.$parent.stageData.id) {
        axios.patch(`${process.env.VUE_APP_API_URL_FB}survey/update/${this.$parent.stageData.id}`, this.$parent.stageData).then((response) => {
        if (response) {
            this.$store.dispatch('updateSnackbar', {
                color: 'success',
                show: true,
                text: 'Question updated successfully!'
            });
            this.$parent.$parent.getSatgeDetails(this.$parent.stageData.id, this.$parent.$parent.selectedStage);
            this.dialogs.newQuestion = false;
          }
        }, (response) => {
          throw new Error(response);
        });
        }
        },
      addQuestion () {
        if (this.$parent && this.$parent.stageData) {
          this.checkDriver = '';
          this.checkSubDriver = '';
          this.$parent.stageData.interactions.push(this.selectedQuesType['0']);
          this.selectedQuesType = null;
          this.dialogs.newQuestion = false;
        }
      },
      addQuestionInLang () {
        if (this.$parent && this.$parent.stageData) {
          this.$parent.$nextTick(() => {
            if (this.lang.language_code === 'ms') {
              this.$parent.stageData.interactions[this.questionIndex].msg_ms = {};
              this.$parent.stageData.interactions[this.questionIndex].msg_ms = JSON.parse(JSON.stringify(this.selectedQuesType['0'].msg));
            } else if (this.lang.language_code === 'tl') {
              this.$parent.stageData.interactions[this.questionIndex].msg_tl = {};
              this.$parent.stageData.interactions[this.questionIndex].msg_tl = JSON.parse(JSON.stringify(this.selectedQuesType['0'].msg));
            }
            this.$parent.$forceUpdate();
            this.selectedQuesType = null;
            this.dialogs.newQuestion = false;
            this.editFlag = false;
            this.questionIndex = null;
          });
        }
      },
      updateQuestion () {
        if (this.$parent && this.$parent.stageData) {
          this.checkDriver = '';
          this.checkSubDriver = '';
          this.$parent.$nextTick(() => {
            this.$parent.stageData.interactions[this.questionIndex] = JSON.parse(JSON.stringify(this.selectedQuesType['0']));
            this.$parent.$forceUpdate();
            this.selectedQuesType = null;
            this.dialogs.newQuestion = false;
            this.editFlag = false;
            this.questionIndex = null;
          });
        }
      },
      updateMLQuestion () {
        if (this.$parent && this.$parent.stageData) {
          this.$parent.$nextTick(() => {
            this.$parent.stageData.interactions[this.questionIndex].msg_ms = JSON.parse(JSON.stringify(this.selectedQuesType[0].msg_ms));
            this.$parent.$forceUpdate();
            this.selectedQuesType = null;
            this.dialogs.newQuestion = false;
            this.editFlag = false;
            this.editMLFlag = false;
            this.questionIndex = null;
          });
        }
      },
      update () {}
    },
    beforeMount () {
        this.getRoles();
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .hover-link:hover {
    color:#0ab39c !important
  }
  .sortable-ghost {
    margin-left: 48px !important;
    margin-right: 48px !important;
    max-width: 100% !important;
    border-radius: 8px;
    border: 1px dashed #4A00E0;
    background-color: white;
    .v-card, .delay, .title {
      opacity: 0;
    }
  }
  .interactions .interaction-card {
    .drag-container {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  
  .action-card {
    position: relative;
    &:after {
      content: "";
      top: -23px;
      width: 1px;
      margin-left: -1px;
      height: 27px;
      position: absolute;
    }
    .actions {
      transition: all 300ms ease;
      opacity: 0;
      position: absolute;
      margin-top: 5.2em;
      left: 1em;
    }
    .title {
      margin-top: 2.2em;
      position: absolute;
    }
    .delay {
      button {
        &:after {
          content: "";
          top: 2.7em;
          width: 1px;
          height: 100%;
          position: absolute;
          left: 17px;
          border-left: 2px dashed #cfd7e0;
        }
      }
      .delay-title {
        position: absolute;
        margin-top: 0.7em;
        margin-left: 48%;
      }
    }
    &:hover {
      .actions {
        opacity: 1;
      }
    }
  }
  
  .list-complete-enter, .list-complete-leave-active {
    opacity: 0;
  }
  
  .emoji-container {
    max-height: 3em;
    max-width: 3em;
  }
  
  .emoji-btn {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    &:hover {
      transform: scale(1.2);
    }
  }
  
  @media screen and (max-width: 599px) {
    .emoji-btn {
      margin-left: 0.3rem !important;
      margin-right: 0.3rem !important;
    }
    .emoji-container {
      max-height: 3em;
      max-width: 3em;
    }
  }
  .list-complete-item {
    padding: 4px;
    margin-top: 4px;
    border: solid 1px;
    transition: all 1s;
  }
  
  .list-complete-enter, .list-complete-leave-active {
    opacity: 0;
  }
  .template-custom-field {
    &.no-margin {
      height:50px!important;
    }
    &.v-text-field--box {
      .v-input__slot {
        min-height: unset!important;
      }
    }
    &.v-text-field--outlined{
      .v-input__slot {
        min-height: unset!important;
      }
    }
    &.v-text-field {
      &.v-text-field--solo {
        .v-input__control {
           min-height: unset!important;
        }
      }
    }
    &.v-text-field--box {
      &.v-text-field--single-line {
        input {
          margin-top: 0px!important;
        }
      }
    }
    &.v-text-field--outlined{
      &.v-text-field--single-line {
        input {
          margin-top: 0px!important;
        }
      }
    }
    &.v-text-field {
      &.v-text-field--enclosed {
        .v-input__prepend-outer {
          margin-top:0px!important;
        }
        .v-input__prepend-inner {
          margin-top:0px!important;
        }
        .v-input__append-outer {
          margin-top:0px!important;
        }
        .v-input__append-inner {
          margin-top:0px!important;
        }
      }
    }
  }
  </style>
  