<template>
  <v-row justify="center">
      <!-- eslint-disable -->
    <v-dialog
      v-model="stageDialog"
      persistent
      max-width="1240px"
    >
      <v-card>
        <v-card-title class="pb-0">
          <v-row class="w-100 ma-0">
            <v-col cols="6"  class="pa-0">
              <span class="text-h5">Stage details</span>
            </v-col>
            <v-col cols="2" class="pa-0">
              <span style="font-size:16px !important;">Chat on behalf of </span>
            </v-col>
            <v-col cols="4"  class="pa-0">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-autocomplete :rules="nameRules" :items="companyAdmins"
                  item-text="display_name"
                  item-value="user_id"
                  required class="pa-0 ma-0" outlined dense v-model="chat_on_behalf">
                </v-autocomplete>
              </v-form>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height:500px;padding-bottom:0px;">
          <v-container class="touchpointAdd" style="max-width:1240px" v-if="stageData && stageData.interactions">
            <v-row>
              <v-col cols="3" class="pr-0 mt-5 pl-0 pt-0" style="border-right:1px solid rgba(128, 128, 128, 0.319);border-left:1px solid rgba(128, 128, 128, 0.319);border-top:1px solid rgba(128, 128, 128, 0.319);">
                <h3 class="pt-5 pl-4 pr-2"> <strong> Draft a new question </strong></h3>
                <p class="grey--text1 b-bottom mb-0 pb-5 pl-4 pr-2">
                  Choose a question type to add new question
                </p>
                <div class=" flex-row flex-wrap d-flex mt-0">
                  <div style="display: flex;" class="flex px-1 py-3 sm12 mt-2 b-bottom cursor-pointer question-type"
                    v-for="(b, bi) in questionTypes" :key="bi" @click="editAction(b)"
                  >
                    <div class="d-flex"  style="align-self:flex-start;">
                      <v-icon color="primary" small class="mt-1 mr-3 ml-3" style="max-width:40px;">
                        {{b.img}}
                      </v-icon>
                    </div>
                    <div>
                      <div class="darken-1" style="font-size:16px">
                        {{b.title}}
                      </div>
                      <div class="grey--text1" style="font-size:10px;">
                      {{b.description}}
                      </div>
                    </div>
                  </div>
                </div>
    
                <div class="mt-0">
                    <div style="display: flex;" class="flex px-1 py-3 sm12 mt-2 b-bottom cursor-pointer question-type"
                    @click="editAction(config.emojiScale)">
                    <div class="d-flex"  style="align-self:flex-start;">
                        <v-icon color="primary" small class="mt-1 mr-3 ml-3" style="max-width:40px;">
                          {{config.emojiScale.img}}
                        </v-icon>
                    </div>
                    <div>
                      <div class="darken-1" style="font-size:16px">
                        {{config.emojiScale.title}}
                      </div>
                        <div class="grey--text1" style="font-size:10px;">
                        {{config.emojiScale.description}}
                        </div>
                    </div>
                    </div>
                </div>
    
                <div class="mt-0">
                    <div style="display: flex;" class="flex px-1 py-3 sm12 mt-2 b-bottom cursor-pointer question-type"
                    v-for="(b, bi) in questionTypesD" :key="bi" @click="editAction(b)">
                    <div class="d-flex" style="align-self:flex-start;">
                        <v-icon color="primary" small class="mt-1 mr-3 ml-3" style="max-width:40px;">
                          {{b.img}}
                        </v-icon>
                    </div>
                    <div>
                      <div class="darken-1" style="font-size:16px">
                        {{b.title}}
                      </div>
                      <div class="grey--text1" style="font-size:10px;">
                        {{b.description}}
                      </div>
                    </div>
                    </div>
                </div>
              </v-col>
              <v-col cols="9" style="border-left:1px solid rgba(128, 128, 128, 0.319);max-height:500px;overflow-y:auto;">
                <draggable
                  :list="stageData.interactions"
                  class="list-group"
                  ghost-class="ghost"
                  :move="checkMove"
                  @start="dragging = true"
                  @end="dragging = false;customSave()"
                >
            <div class="draggable-item d-flex my-2"
              v-for="(q, qi) in stageData.interactions"
              :key="qi"
            >
              <v-flex class="pa-0 my-1 action-card text-center" sm12>
                  <div class="mb-1 box-container">
                  <div style="min-height:48px" class="d-flex flex-wrap flex-row">
                      <v-flex class="text-left" style="align-self:center;">
                        <div v-if="q.driver !== 'Mood'">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" class="handle pl-3" style="font-size:large;" v-bind="attrs" v-on="on">
                              fa-regular fa-arrows-alt
                              </v-icon>
                            </template>
                              Move
                          </v-tooltip>
                        </div>
                      </v-flex>
                      <v-flex xs1 v-if="q.type !== 'text' && q.driver !== 'Mood'"
                        class="font-weight-bold pl-2 pr-2 pt-2 text-right"
                      >
                        Driver:
                      </v-flex>
                      <v-flex v-if="q.type !== 'text' && q.driver !== 'Mood'" lay class="text-left driver-name" xs3>
                          <v-chip v-if="q.driver" class="mt-1">{{q.driver}}</v-chip>
                          <v-chip style="padding: 10px 20px !important" v-else class="mt-1">--</v-chip>
                      </v-flex>

                      <v-flex xs2 v-if="q.type !== 'text' && q.driver !== 'Mood'" class="font-weight-bold pl-2 pr-2 pt-2 text-right">
                        Sub Driver:
                      </v-flex>
                      <v-flex v-if="q.type !== 'text' && q.driver !== 'Mood'" lay class="text-left sub-driver-name" xs3>
                        <v-chip v-if="q.sub_driver" class="mt-1">{{q.sub_driver}}</v-chip>
                        <v-chip style="padding: 10px 20px !important" v-else class="mt-1">--</v-chip>
                      </v-flex>

                      <v-spacer> </v-spacer>

                      <v-flex xs2 class="text-center">
                        <div class="d-flex flex-row">
                          <v-flex xs6>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="grey" class="mt-3" @click="duplicateAction(q)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                las la-copy
                                </v-icon>
                              </template>
                                Copy
                            </v-tooltip>
                          </v-flex>
                          <v-flex xs3>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" class="mt-3" @click="editAction(q, qi, true)"
                                v-bind="attrs"
                                v-on="on"
                              >
                              mdi-pencil-outline
                              </v-icon>
                            </template>
                              Edit
                          </v-tooltip>
                          </v-flex>
                          <v-flex xs3 v-if="q.driver !== 'Mood'">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="grey" class="mt-3" @click="deleteAction(qi)"
                                  v-bind="attrs"
                                  v-on="on"
                                >mdi-delete-outline
                                </v-icon>
                              </template>
                                Delete
                            </v-tooltip>
                          </v-flex>

                        </div>
                      </v-flex>
                      <v-flex xs12>
                      <v-divider></v-divider>
                      </v-flex>
                  </div>
                  <div class="pt-4 pa-3 d-flex flex-row flex-wrap align-center">
                      <v-flex class="offset-1" xs10 v-if="q.type === 'scale'">
                      <v-text-field
                          v-if="q.msg"
                          maxlength="300"
                          v-model="q.msg[0].text"
                          class="elevation-0 grey--text text--lighten-2"
                          name="interactionText"
                          label="Question"
                          autofocus
                          data-vv-as="interaction text"
                          v-validate="'required'"
                          single-line
                          disabled
                          outlined
                      >
                      </v-text-field>

                      <div class="d-flex flex-row flex-wrap justify-center"
                        v-if="q.msg && q.msg[0].scaleType === 'emoji'"
                      >
                          <v-flex xs2 class="text-center"
                          v-for="(r, ri) in q.msg[0].scale" :key="ri">
                          <v-menu
                              absolute
                              disabled
                              offset-y
                              style="max-width: 600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="emoji-btn"
                              slot="activator"
                              fab text rounded disabled
                              v-bind="attrs"
                              v-on="on"
                              >
                              <img alt="image" v-if="r.img" style="width:20%;" :src="getImgUrl(r.img)"
                              class="emoji-container" />
                              </v-btn>
                            </template>
                              <v-list class="pa-3">
                              <v-text-field
                                  class="grey--text text--lighten-2"
                                  maxlength="50"
                                  outlined
                                  v-model="r.title"
                                  flat
                                  @click.stop
                                  name="quickReply"
                                  :label="`Rating ${ri+1}`"
                                  disabled
                              ></v-text-field>

                              <v-text-field
                                  class="grey--text text--lighten-2"
                                  maxlength="300"
                                  outlined
                                  v-model="r.postback"
                                  flat
                                  @click.stop
                                  name="postback"
                                  label="Amara's response"
                                  disabled
                              ></v-text-field>

                              <v-flex xs12 class="text-right">
                                  <v-btn color="#0ab39c" class="white--text" small disabled>
                                    Done
                                  </v-btn>
                              </v-flex>
                              </v-list>
                          </v-menu>
                          <p class="text-center">
                              {{r.title}}
                          </p>
                          </v-flex>
                      </div>

                      <div class="d-flex flex-row flex-wrap justify-center"
                        v-if="q.msg && q.msg[0].scaleType === 'number'">
                          <v-flex xs2 class="text-center"
                            v-for="(r, ri) in q.msg[0].scale" :key="ri"
                          >
                          <v-menu
                              absolute
                              offset-y
                              disabled
                              style="max-width: 600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="emoji-btn"
                                :color="r.color"
                                outlined
                                text
                                fab
                                large
                                v-bind="attrs"
                                v-on="on"
                                depressed
                              >
                                <strong class="headline">
                                  {{r.value}}
                                </strong>
                              </v-btn>
                            </template>
                              <v-list class="pa-3">
                              <v-text-field
                                  class="grey--text text--lighten-2"
                                  maxlength="50"
                                  outlined
                                  v-model="r.title"
                                  flat
                                  disabled
                                  @click.stop
                                  name="quickReply"
                                  :label="`Rating ${ri+1}`"
                              ></v-text-field>

                              <v-text-field
                                  class="grey--text text--lighten-2"
                                  maxlength="300"
                                  outlined
                                  v-model="r.postback"
                                  flat
                                  disabled
                                  @click.stop
                                  name="postback"
                                  label="Amara's response"
                              ></v-text-field>

                              <v-flex xs12 class="text-right">
                                  <v-btn color="#0ab39c"  class="white--text" small disabled>
                                    Done
                                  </v-btn>
                              </v-flex>
                              </v-list>
                          </v-menu>
                          <p class="text-center">
                              {{r.title}}
                          </p>
                          </v-flex>
                      </div>
                      </v-flex>
                      <v-flex class="offset-1" xs10 v-if="q.type === 'textInput'">
                      <v-text-field
                          maxlength="300"
                          v-model="q.msg['0'].text"
                          class="elevation-0 grey--text text--lighten-2"
                          :name="`interactionText_${qi}`"
                          label="Question"
                          autofocus
                          disabled
                          data-vv-as="interaction text"
                          v-validate="'required'"
                          single-line
                          outlined
                      >
                          <v-fade-transition slot="prepend">
                          <img alt="image" width="30" height="30"
                          :src="getImgUrl('amara-60')">
                          </v-fade-transition>
                      </v-text-field>
                      </v-flex>

                      <!-- Text -->
                      <v-flex class="offset-1" xs10 v-if="q.type === 'text'">
                      <v-text-field
                          maxlength="300"
                          v-model="q.msg['0'].text"
                          class="elevation-0 grey--text text--lighten-2"
                          :name="`interactionText_${qi}`"
                          label="What should Amara say?"
                          autofocus
                          disabled
                          data-vv-as="interaction text"
                          v-validate="'required'"
                          single-line
                          outlined
                      >
                          <v-fade-transition slot="prepend">
                          <img alt="image" width="30" height="30"
                          :src="getImgUrl('amara-60')">
                          </v-fade-transition>
                      </v-text-field>
                      </v-flex>

                      <!-- Image -->
                      <v-flex class="" v-if="q.type === 'image'">
                      <v-menu
                          absolute
                          v-if="q.msg"
                          offset-y
                          style="max-width: 600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-img
                          height="200"
                          width="300"
                          slot="activator"
                          v-bind="attrs"
                          v-on="on"
                          max-height="200"
                          max-width="300"
                          v-if="q.msg"
                          :src="q.msg[0].imgUrl"
                          :lazy-src="q.msg[0].imgUrl"
                          aspect-ratio="1"
                          class="grey lighten-2 mx-auto"
                          >
                          <div
                            class="d-flex flex-row fill-height align-center justify-center ma-0"
                            slot="placeholder"
                          >
                            <div class="">
                              <v-icon class="mr-2">las la-plus-square</v-icon>
                              <strong class="mb-2">
                                Add Image
                              </strong>
                              </div>
                              <v-progress-circular v-show="q.msg && q.msg[0].imgUrl"
                              indeterminate color="grey lighten-5">
                              </v-progress-circular>
                            </div>
                          </v-img>
                          </template>
                          <v-list class="pa-3">
                          <v-text-field
                              maxlength="1000"
                              outlined
                              v-if="q.msg"
                              v-model="q.msg[0].imgUrl"
                              flat
                              disabled
                              @click.stop
                              name="url"
                              label="Your image URL"
                          ></v-text-field>
                          <v-flex xs12 class="text-right">
                              <v-btn color="#0ab39c"  class="white--text" small disabled>
                                Done
                              </v-btn>
                          </v-flex>
                          </v-list>
                      </v-menu>
                      </v-flex>

                      <!-- Card -->
                      <v-flex class="" v-if="q.type === 'card'">
                      <v-card class="mx-auto" width="300"
                          v-for="(c, ci) in q.msg" :key="ci"
                      >
                          <v-menu
                          absolute
                          offset-y
                          style="max-width: 600px"
                          >
                          <template v-slot:activator="{ on, attrs }">
                          <v-img
                            height="200"
                            width="300"
                            slot="activator"
                            max-height="200"
                            max-width="300"
                            :src="c.imgUrl"
                            v-bind="attrs"
                            v-on="on"
                            :lazy-src="c.imgUrl"
                            aspect-ratio="1"
                            class="grey lighten-2 mx-auto"
                          >
                              <div
                                class="d-flex flex-row fill-height align-center justify-center ma-0"
                                slot="placeholder"
                              >
                              <div class="">
                                  <v-icon class="mr-2">las la-plus-square</v-icon>
                                  <strong class="mb-2">
                                  Add Image
                                  </strong>
                              </div>
                              <v-progress-circular v-show="c.imgUrl"
                              indeterminate color="grey lighten-5">
                              </v-progress-circular>
                              </div>
                          </v-img>
                          </template>
                          <v-list class="pa-3">
                              <v-text-field
                              maxlength="1000"
                              outlined
                              v-model="c.imgUrl"
                              flat
                              disabled
                              @click.stop
                              name="url"
                              label="Your image URL"
                              ></v-text-field>
                              <v-flex xs12 class="text-right">
                              <v-btn color="#0ab39c"  class="white--text" small disabled>
                                Done
                              </v-btn>
                              </v-flex>
                          </v-list>
                          </v-menu>
                          <h3 class="headline mb-0 font-weight-bold w-100">
                          <v-text-field
                              maxlength="300"
                              solo
                              v-model="c.text"
                              flat
                              disabled
                              @click.stop
                              name="cardTitle"
                              label="Enter your message here"
                          ></v-text-field>
                          </h3>
                          <div>
                          <v-textarea
                              maxlength="500"
                              flat
                              rows="2"
                              solo
                              disabled
                              v-model="c.description"
                              name="cardDescription"
                              label="Enter card description here"
                          ></v-textarea>
                          </div>

                          <v-card-actions>
                          <div class="text-left">
                              <v-menu v-for="(r, ri) in c.quickReplies" :key="ri"
                              absolute
                              offset-y
                              disabled
                              class="mb-1"
                              style="width: 20em;"
                              >
                              <template v-slot:activator="{ on, attrs }">
                              <v-btn color="#0ab39c" block outlinedd slot="activator" v-bind="attrs" v-on="on">
                              {{r.title}}</v-btn>
                              </template>
                              <v-list class="pa-3">
                                  <v-text-field
                                  class="grey--text text--lighten-2"
                                  maxlength="50"
                                  outlined
                                  v-model="r.title"
                                  flat
                                  disabled
                                  @click.stop
                                  name="quickReply"
                                  :label="`Rating ${ri+1}`"
                                  ></v-text-field>

                                  <v-text-field
                                  class="grey--text text--lighten-2"
                                  maxlength="300"
                                  outlined
                                  v-model="r.postback"
                                  flat
                                  disabled
                                  @click.stop
                                  name="postback"
                                  label="Amara's response"
                                  ></v-text-field>

                                  <v-flex xs12 class="text-right">
                                  <v-btn color="error" small text disabled
                                  @click="deleteReply(qi, ci, ri)">Delete</v-btn>
                                  <v-btn color="#0ab39c"  class="white--text" small disabled>
                                    Done
                                  </v-btn>
                                  </v-flex>
                              </v-list>
                              </v-menu>

                              <!-- Add new quick reply -->
                              <p class="cursor-pointer mb-0 text-sm-center"
                              v-if="c.quickReplies.length < 5"
                              style="width: 20em;" @click="addReplies(qi, ci, 'Button')">
                              <v-btn color="accent" class="mr-2" small outlined icon disabled>
                                  <v-icon small>las la-plus</v-icon>
                              </v-btn>Add reply
                              </p>
                          </div>
                          </v-card-actions>
                      </v-card>
                      </v-flex>

                      <!-- Carousel -->
                      <v-flex class="" v-if="q.type === 'carousel'">
                      <div class="flex-row flex-wrap d-flex">
                          <v-card class="mr-3" width="300"
                            v-for="(c, ci) in q.msg" :key="ci"
                          >
                          <v-menu
                              absolute
                              disabled
                              offset-y
                              style="max-width: 600px"
                          >
                          <template v-slot:activator="{ on, attrs }">
                              <v-img
                              height="200"
                              width="300"
                              v-bind="attrs"
                              v-on="on"
                              slot="activator"
                              max-height="200"
                              max-width="300"
                              :src="c.imgUrl"
                              :lazy-src="c.imgUrl"
                              aspect-ratio="1"
                              class="grey lighten-2 mx-auto"
                              >
                              <div
                                class="d-flex flex-wrap flex-row fill-height align-center justify-center ma-0"
                                slot="placeholder"
                              >
                                  <div class="">
                                  <v-icon class="mr-2">las la-plus-square</v-icon>
                                  <strong class="mb-2">
                                      Add Image
                                  </strong>
                                  </div>
                                  <v-progress-circular v-show="c.imgUrl"
                                  indeterminate color="grey lighten-5">
                                  </v-progress-circular>
                              </div>
                              </v-img>
                          </template>
                              <v-list class="pa-3">
                              <v-text-field
                                  maxlength="1000"
                                  outlined
                                  v-model="c.imgUrl"
                                  flat
                                  disabled
                                  @click.stop
                                  name="url"
                                  label="Your image URL"
                              ></v-text-field>
                              <v-flex xs12 class="text-right">
                                  <v-btn color="#0ab39c"  class="white--text" small disabled>
                                    Done
                                  </v-btn>
                              </v-flex>
                              </v-list>
                          </v-menu>
                          <h3 class="headline mb-0 font-weight-bold w-100">
                              <v-text-field
                              maxlength="300"
                              solo
                              v-model="c.text"
                              flat
                              disabled
                              @click.stop
                              name="cardTitle"
                              label="Enter your message here"
                              ></v-text-field>
                          </h3>
                          <div>
                              <v-textarea
                              maxlength="500"
                              flat
                              disabled
                              rows="2"
                              solo
                              v-model="c.description"
                              name="cardDescription"
                              label="Enter card description here"
                              ></v-textarea>
                          </div>

                          <v-card-actions>
                              <div class="text-left">
                              <v-menu v-for="(r, ri) in c.quickReplies" :key="ri"
                                  absolute
                                  offset-y
                                  class="mb-1"
                                  style="width: 20em;"
                              >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="#0ab39c" block outlinedd slot="activator" v-bind="attrs" v-on="on">
                                  {{r.title}}</v-btn>
                              </template>
                                  <v-list class="pa-3">
                                  <v-text-field
                                      class="grey--text text--lighten-2"
                                      maxlength="50"
                                      outlined
                                      v-model="r.title"
                                      flat
                                      disabled
                                      @click.stop
                                      name="quickReply"
                                      :label="`Rating ${ri+1}`"
                                  ></v-text-field>

                                  <v-text-field
                                      class="grey--text text--lighten-2"
                                      maxlength="300"
                                      outlined
                                      v-model="r.postback"
                                      flat
                                      disabled
                                      @click.stop
                                      name="postback"
                                      label="Amara's response"
                                  ></v-text-field>

                                  <v-flex xs12 class="text-right">
                                      <v-btn color="error" small text
                                      @click="deleteReply(qi, ci, ri)" disabled>Delete</v-btn>
                                      <v-btn color="#0ab39c"  class="white--text" small disabled>
                                        Done
                                      </v-btn>
                                  </v-flex>
                                  </v-list>
                              </v-menu>

                              <!-- Add new quick reply -->
                              <p class="cursor-pointer mb-0 text-sm-center"
                              v-if="c.quickReplies.length < 5"
                              style="width: 20em;" @click="addReplies(qi, ci, 'Button')">
                                  <v-btn color="accent" class="mr-2" small disabled outlinedd icon>
                                  <v-icon small>las la-plus</v-icon>
                                  </v-btn>Add reply
                              </p>
                              </div>
                          </v-card-actions>
                          </v-card>
                          <!-- Add new quick reply -->
                          <v-card>
                          <p class="cursor-pointer mb-0 text-sm-center"
                          v-if="q.msg.length < 5"
                          style="width: 20em;" @click="addCard(qi)">
                              <v-btn color="accent" class="mr-2" small disabled outlinedd icon>
                              <v-icon small>las la-plus</v-icon>
                              </v-btn>Add card
                          </p>
                          </v-card>
                      </div>
                      </v-flex>

                      <!-- Quick replies -->
                      <v-flex class="offset-1" xs10
                      v-if="q.type === 'quickReplies' || q.type === 'closeEnded'">
                      <v-text-field
                          v-if="q.msg"
                          maxlength="300"
                          class="elevation-0 grey--text text--lighten-2"
                          v-model="q.msg[0].text"
                          :name="`interactionText`"
                          label="Question"
                          autofocus
                          disabled
                          data-vv-as="interaction text"
                          v-validate="'required'"
                          single-line
                          outlined
                      >
                          <v-fade-transition slot="prepend">
                          <img alt="image" width="30" height="30"
                          :src="getImgUrl('amara-60')">
                          </v-fade-transition>
                      </v-text-field>

                      <div class="text-left" v-if="q.msg">
                          <v-menu v-for="(c, ci) in q.msg[0].quickReplies" :key="ci"
                          absolute
                          disabled
                          offset-y
                          style="max-width: 600px"
                          >
                          <template v-slot:activator="{ on, attrs }">
                          <v-btn color="#0ab39c" outlinedd slot="activator" class="mr-2" disabled v-bind="attrs" v-on="on">
                            {{c.title}}
                          </v-btn>
                          </template>
                          <v-list class="pa-3">
                              <v-text-field
                              class="grey--text text--lighten-2"
                              maxlength="50"
                              outlined
                              v-model="c.title"
                              flat
                              disabled
                              @click.stop
                              name="quickReply"
                              :label="`Rating ${ci+1}`"
                              ></v-text-field>

                              <v-text-field
                              class="grey--text text--lighten-2"
                              maxlength="300"
                              outlined
                              v-model="c.postback"
                              flat
                              disabled
                              @click.stop
                              name="postback"
                              label="Amara's response"
                              ></v-text-field>

                              <v-flex xs12 class="text-right">
                              <v-btn color="error" small text
                              v-if="q.type === 'quickReplies'"
                              @click="deleteReply(qi, 0, ci)" disabled>Delete</v-btn>
                              <v-btn color="#0ab39c"  class="white--text" small disabled>
                                Done
                              </v-btn>
                              </v-flex>
                          </v-list>
                          </v-menu>

                          <!-- Add new quick reply -->
                          <span class="cursor-pointer" @click="addReplies(qi, 0, 'Answer')"
                          v-if="q.msg && q.msg[0].quickReplies.length < 5
                          && q.type === 'quickReplies'">
                          <v-btn color="accent" small disabled outlinedd icon>
                              <v-icon small>las la-plus</v-icon>
                          </v-btn>Add reply
                          </span>
                      </div>
                      </v-flex>

                  </div>
                  </div>
              </v-flex>
            </div>
          </draggable>
          </v-col>
        </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="grey"
            text
            @click="stageDialog = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!editView" :loading='loading'
            color="#0ab39c"  style="color: white;"
            @click="validate()"
          >
          Activate
          </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <newQuestion ref="newQuestion" v-show="questionSource"></newQuestion>
  </v-row>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import newQuestion from './editQuestion';
  export default {
    components: {
      axios,
      newQuestion,
      draggable
    },
    data: () => ({
      questionSource: false,
      loading: false,
      nameRules: [
        v => !!v || 'This field is required',
      ],
      valid: true,
      stageDialog: false,
      items: ['foo', 'bar', 'fizz', 'buzz'],
      chat_on_behalf: '',
      dialogTouchpoint: false,
      editView: false,
      imageData: '',
      companyAdmins: [],
      stageData: null,
      openEnded: {
        delay: 2000,
        title: 'Open ended question',
        description: 'This require respondents to type their answer into a comment box',
        type: 'textInput',
        img: 'fa-regular fa-keyboard',
        msg: [{
          text: ''
        }],
        responseRequired: true,
        response: []
      },
      ratingScale: {
        delay: 2000,
        title: 'Rating scale',
        description: 'The respondent selects the number that most accurately represents their response',
        type: 'scale',
        role: '',
        img: 'fa-regular fa-arrows-alt-h',
        msg: [{
          text: '',
          scaleType: 'number',
          scale: [
            {
              title: 'Awful',
              color: 'red',
              value: 1,
              postback: 'I am really sorry to hear that'
            }, {
              title: 'Not very good',
              color: 'deep-orange',
              value: 2,
              postback: 'That\'s not good'
            }, {
              title: 'Good',
              color: 'orange',
              value: 3,
              postback: 'I think we can improve'
            }, {
              title: 'Really good',
              color: 'light-green',
              value: 4,
              postback: 'That\'s good to know'
            }, {
              title: 'Brilliant',
              color: 'green',
              value: 5,
              postback: 'I am happy to know'
            }
          ]
        }],
        responseRequired: true,
        response: []
      },
      yesNo: {
        delay: 2000,
        title: 'Yes/No',
        description: 'Used where ambiguity is the enemy',
        type: 'closeEnded',
        msg: [{
          text: '',
          quickReplies: [
            {
              title: 'Yes',
              value: 5,
              postback: 'Good to know!'
            }, {
              title: 'No',
              value: 1,
              postback: 'That\'s sad'
            }
          ]
        }],
        img: 'fa-regular fa-reply-all',
        responseRequired: true,
        response: []
      },
      emojiScale: {
          delay: 2000,
          title: 'Emoji Scale',
          description: 'Used to determine how someone feels about your company',
          type: 'scale',
          role: 'mood',
          img: 'fa-regular fa-smile',
          msg: [{
            text: '',
            scaleType: 'emoji',
            scale: [
              {
                title: 'Terrible',
                img: 'crying',
                value: 1,
                postback: `I am very sorry to hear that 😞`
              }, {
                title: 'Disappointed',
                img: 'sad',
                value: 2,
                postback: 'Ah! That\'s bad.'
              }, {
                title: 'Okay',
                img: 'shocked',
                value: 3,
                postback: 'Hmmmmm alright!'
              }, {
                title: 'Good',
                img: 'happy',
                value: 4,
                postback: 'That\'s nice!'
              }, {
                title: 'Awesome',
                img: 'in-love',
                value: 5,
                postback: 'I am so glad to know that 🙂'
              }
            ]
          }],
          responseRequired: true,
          response: []
        },
        questionTypes: [{
        delay: 2000,
        title: 'Open ended question',
        description: 'This require respondents to type their answer into a comment box',
        type: 'textInput',
        img: 'fa-regular fa-keyboard',
        msg: [{
          text: ''
        }],
        responseRequired: true,
        response: []
      }],
      questionTypesD: [{
        delay: 2000,
        title: 'Rating scale',
        description: 'The respondent selects the number that most accurately represents their response',
        type: 'scale',
        role: '',
        img: 'fa-regular fa-arrows-alt-h',
        msg: [{
          text: '',
          scaleType: 'number',
          scale: [
            {
              title: 'Awful',
              color: 'red',
              value: 1,
              postback: 'I am really sorry to hear that'
            }, {
              title: 'Not very good',
              color: 'deep-orange',
              value: 2,
              postback: 'That\'s not good'
            }, {
              title: 'Good',
              color: 'orange',
              value: 3,
              postback: 'I think we can improve'
            }, {
              title: 'Really good',
              color: 'light-green',
              value: 4,
              postback: 'That\'s good to know'
            }, {
              title: 'Brilliant',
              color: 'green',
              value: 5,
              postback: 'I am happy to know'
            }
          ]
        }],
        responseRequired: true,
        response: []
      }, {
        delay: 2000,
        title: 'Yes/No',
        description: 'Used where ambiguity is the enemy',
        type: 'closeEnded',
        msg: [{
          text: '',
          quickReplies: [
            {
              title: 'Yes',
              value: 5,
              postback: 'Good to know!'
            }, {
              title: 'No',
              value: 1,
              postback: 'That\'s sad'
            }
          ]
        }],
        img: 'fa-regular fa-reply-all',
        responseRequired: true,
        response: []
      }],
      config: {
        emojiScale: {
          delay: 2000,
          title: 'Emoji Scale',
          description: 'Used to determine how someone feels about your company',
          type: 'scale',
          role: 'mood',
          img: 'fa-regular fa-smile',
          msg: [{
            text: '',
            scaleType: 'emoji',
            scale: [
              {
                title: 'Terrible',
                img: 'crying',
                value: 1,
                postback: `I am very sorry to hear that 😞`
              }, {
                title: 'Disappointed',
                img: 'sad',
                value: 2,
                postback: 'Ah! That\'s bad.'
              }, {
                title: 'Okay',
                img: 'shocked',
                value: 3,
                postback: 'Hmmmmm alright!'
              }, {
                title: 'Good',
                img: 'happy',
                value: 4,
                postback: 'That\'s nice!'
              }, {
                title: 'Awesome',
                img: 'in-love',
                value: 5,
                postback: 'I am so glad to know that 🙂'
              }
            ]
          }],
          responseRequired: true,
          response: []
        },
      }
    }),
    computed: {
      ...mapState({
        snackbar: state => state.snackbar,
        user: state => state.user
      })
    },
    methods: {
      checkMove (e) {
        return e.draggedContext.futureIndex;
      },
      customSave () {
        console.log('moving it');
        setTimeout(() => {
          this.saveTouchpointOndrag();
        }, 200);
      },
      deactivateStage () {
        this.$swal({
          title: 'Are you sure?',
          text: "You want to deactivate this touchpoint!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, deactivate it!'
        }).then((result) => {
          if (result.isConfirmed) {
            let temp = [];
            let temp1 = {}
            temp1.stage_id = this.$parent.selectedStage,
            temp1.status = "draft"
            temp.push(temp1);
            axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
            axios.patch(`${process.env.VUE_APP_API_URL}stage/bulk-update/`, {
                updated_stages: temp
            }).then((response) => {
              if (response && response.data) {
                  this.stageDialog = false;
                  this.loading = false;
                  this.$parent.getSatges(this.$route.query.cid);
                  this.$store.dispatch('updateSnackbar', {
                    color: 'success',
                    show: true,
                    text: 'Touchpoint deactivated successfully!'
                  });
                // this.$refs.stagedetails.stageDialog = true;
                // this.$refs.stagedetails.stageData = response.data;
                // console.log(this.company);
              }
            }).catch((err) => {
              if (err.response.status === 429) {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.error
                });
              } else if (err.response.status === 400) {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.message
                });
              } else {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: 'Unable to fetch details, Please try again later!'
                });
              }
            });
          }
        });
        },
      editAction (q, qi, editMLFlag) {
        this.questionSource =  true;
        this.$refs.newQuestion.selectedQuesType = {};
        this.$refs.newQuestion.questionIndex = null;
        this.$refs.newQuestion.editMLFlag = editMLFlag;
        this.$refs.newQuestion.questionIndex = qi;
        if (qi === undefined) {
          this.$refs.newQuestion.questionIndex = null;
        }
        this.$refs.newQuestion.getRoles();
        this.$refs.newQuestion.selectedQuesType['0'] = q;
        this.$refs.newQuestion.dialogs.newQuestion = true;

      },
      deleteAction (qi) {
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this touchpoint!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
        this.stageData.interactions.splice(qi, 1);
        this.updateTouchpointQuestion();
          }
        });
      },
      saveTouchpointOndrag () {
      if (this.stageData.id) {
        axios.patch(`${process.env.VUE_APP_API_URL_FB}survey/update/${this.stageData.id}`, this.stageData).then((response) => {
        if (response) {
            this.$parent.getSatgeDetails(this.stageData.id, this.$parent.selectedStage);
          }
        }, (response) => {
          throw new Error(response);
        });
        }
        },
      updateTouchpointQuestion () {
      if (this.stageData.id) {
        axios.patch(`${process.env.VUE_APP_API_URL_FB}survey/update/${this.stageData.id}`, this.stageData).then((response) => {
        if (response) {
            this.$store.dispatch('updateSnackbar', {
                color: 'success',
                show: true,
                text: 'Question deleted successfully!'
            });
          }
        }, (response) => {
          throw new Error(response);
        });
        }
        },
      getImgUrl (pet) {
      const images = require.context('@/assets/', false, /\.png$/);
      return images(`./${pet}.png`);
      },
      validate () {
        this.$refs.form.validate()
        setTimeout(() => {
          console.log('test', this.valid);
          if (this.valid) {
            this.loading = true;
            this.liveStage()
          } else {
            this.loading = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Please select chat from user first!'
            });
          }
        }, 1000);
      },
      getAdmins (id) {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}users/admins/${id}`).then((response) => {
          if (response && response.data) {
            this.companyAdmins = response.data.data;
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      liveStage () {
        let temp = [];
        let temp1 = {}
        temp1.chatFromUser_id = this.chat_on_behalf,
        temp1.stage_id = this.$parent.selectedStage,
        temp1.status = "live"
        temp.push(temp1);
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.patch(`${process.env.VUE_APP_API_URL}stage/bulk-update/${this.$parent.selectedStage}`, {
          updated_stages: temp
        }).then((response) => {
          if (response && response.data) {
            this.stageDialog = false;
            this.loading = false;
            this.$parent.getSatges(this.$route.query.cid);
            this.$store.dispatch('updateSnackbar', {
              color: 'success',
              show: true,
              text: 'Touchpoint activated!'
            });
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.loading = false;
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.loading = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      }
    },
    mounted() {
      this.getAdmins(this.$route.query.cid);
    }
  }
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.grey--text1 {
  color: grey;
  line-height: 1rem;
}
.b-bottom {
  border-bottom: 1px solid rgba(128, 128, 128, 0.319);
}
.fileName {
  white-space: pre-wrap;
  vertical-align: middle;
  text-align: center;
  font-size: 1em;
  line-height: 1.5;
  display: inline-block;
  background: #f1f1f1;
  width:100%;
  padding: 45px 34px;
  font-family: Helvetica, Arial, sans-serif;
}
.touchpointAdd {
  input[type=file] {
      width:100%;
  }
  input[type=file]:before {
  content: 'Drag and drop or Click \A to add touchpoints.';
  white-space: pre-wrap;
  vertical-align: middle;
  text-align: center;
  font-size: 1em;
  line-height: 1.5;
  display: inline-block;
  background: #f1f1f1;
  width:100%;
  padding: 45px 34px;
  font-family: Helvetica, Arial, sans-serif;
  }

  input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
  }
}
.draggable-item {
  border: 1px solid #dedede;
  overflow-y: auto;
}

</style>