<template>
  <v-app none class="white">
      <v-app-bar v-if="user.sub_type || user.type" app style="z-index:15;background: white !important;">
      <v-row style=" width: 100%; min-width: 100%;">
        <v-col class="logoImg" cols="2">
          <v-img class="logoIImg" style="width:70%;" src="./assets/amara-black-logo.png"></v-img>
        </v-col>
        <v-col cols="9" style="align-self:center;">
        </v-col>
        <v-col class="Menu" cols="1" style="padding-top:15px;width: 160px;margin-left:-40px;">
          <v-menu bottom offset-y :style="{ zIndex: 9999 }">
            <template v-slot:activator="{ on, attrs }">
        <div
        class="menu-button"
        v-bind="attrs"
        v-on="on"
        style="height: 60px; width: 160px; cursor: pointer; display: flex; align-items: center; background-color: #f6f6f8; font-size: 16px; font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;"
        >
    <div style="width: 40px; height: 40px; margin: 10px;">
    <img
      v-if="user.user_profile.profilePicture"
      :src="user.user_profile.profilePicture"
      alt="Profile Picture"
      style="width: 100%; height: 100%; border-radius: 50%;"
    />
    <img
      v-else
      src="@/assets/dummy.png"
      alt="Dummy Image"
      style="width: 100%; height: 100%; border-radius: 50%;"
    />
  </div>
  <div>
    <div>{{ user.user_profile.first_name }}</div>
    <div style="font-size: 14px; color: gray;">{{ user.user_profile.employee_id }}</div>
  </div>
</div>
    </template>
    <v-list style="border-radius: 6px;padding-top:0px;">
      <v-list-item style="background-color: #0ab39c;color: white;">
        <v-list-item style="padding:0px !important">
  <div style="width: 40px; height: 40px; margin-right: 10px;">
    <img
      v-if="user.user_profile.profilePicture"
      :src="user.user_profile.profilePicture"
      alt="Profile Picture"
      style="width: 100%; height: 100%; border-radius: 50%;"
    />
    <img
      v-else
      src="@/assets/dummy.png"
      alt="Dummy Image"
      style="width: 100%; height: 100%; border-radius: 50%;"
    />
  </div>
  <v-list-item-content>
    <v-list-item-title style="font-size: 16px; color:white ; font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
      {{ user.display_name }}
    </v-list-item-title>
    <v-list-item-title style="font-size: 14px; color: white; font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; padding-top: 5px;">
      {{ user.email }}
    </v-list-item-title>
  </v-list-item-content>
</v-list-item>

      </v-list-item>
      <v-divider></v-divider>
      <v-list-item @click="logout()">
        <v-list-item-content>
          <v-list-item-title> <v-icon v-on="on" @click="logout()" style="padding: 5px;background: #e8e8e8;border-radius: 32px;color: #96969a;margin-right: 10px;">mdi-logout</v-icon>Log Out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</v-col>
      </v-row>
      
    <div style=" width:100%;   
    padding-bottom: 10px;">
    
    </div>
  </v-app-bar>

  
  <v-main>
    <v-snackbar
          :timeout="6000"
          :color="snackbar.color"
          :multi-line="snackbar.mode === 'multi-line'"
          :vertical="snackbar.mode === 'vertical'"
          v-model="snackbar.show"
        >
          <div class="d-flex justify-space-between align-center">
            <div>{{ snackbar.text }}</div>
            <v-btn text @click.native="snackbar.show = false">Close</v-btn>
          </div>
        </v-snackbar>
    <v-container fluid>
      <router-view />
    </v-container>
  </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import { mapState } from 'vuex';
Vue.use(VueSweetalert2);
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard' },
          { title: 'Photos', icon: 'mdi-image' },
          { title: 'About', icon: 'mdi-help-box' },
        ],
        right: null,
        searchCompany: '',
    //
  }),
  computed: {
    ...mapState({
      snackbar: state => state.snackbar,
      user: state => state.user
    })
  },
  methods: {
    openModal () {
      console.log(this);
    },
    logout () {
      this.$swal({
        title: 'Log out ?',
        text: `You will be returned to the login screen.`,
        icon: 'warning',
        confirmButtonColor: '#050550',
        showCancelButton: true,
        cancelButtonColor: '#d6d4d4',
        confirmButtonText: 'Log out'
      }).then((result) => {
        if (result.isConfirmed) {
        axios.post(`${process.env.VUE_APP_API_URL_FB}logout`).then((response) => {
        if (response && response.data.status === 200) {
          this.$store.dispatch('deleteSession', 'logout');
          this.$router.push('/login');
        } else {
          this.$notify({
            group: 'foo',
            title: 'Error while logging out!',
            type: 'warn'
          });
          this.$store.dispatch('deleteSession', 'logout');
          this.$router.push('/login');
        }
      }, () => {
        this.$store.dispatch('deleteSession', 'logout');
        this.$router.push('/login');
      });
        }
      });
    },
    changeTab (url) {
      this.$router.push(`/${url}`);
    }
  }
};
</script>
<style lang="scss">
  .container--fluid {
    padding: 0 !important; 
  }
  .grecaptcha-badge {
    display:none !important;
    box-shadow: none !important;
  }
.v-btn--active {
    background-color: #050550 !important;
  }
.disabledView {
  .v-btn--active {
    background-color: #858585 !important;
  }
}
.headingprofile {
  text-align: left;
  h2 {
        color: #263238;
        font-weight: 500;
    line-height: 1.1;
    font-size: 20px;
    margin: 0 0 6px;
    width: 100%;
  }
}
.activeTab {
    background-color: #0ab39c;
    color: white;
    position: relative;
    text-align: center;
    padding: 10px;
}

.activeTab::after {
    content: "";
    position: absolute;
    bottom: -6px; 
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 12px; /* Size of the arrow */
    height: 12px;
    background-color: #0ab39c;
}


</style>
