<template>
  <div class="companyprofile px-2" style="background: rgb(248, 248, 248); border-radius: 10px; padding: 25px 30px;">
    <v-flex class="px-2" v-if="listview">
      <div class="px-1 py-2" v-if="emailData.length > 0" >
        <v-row class="w-100 ma-0 text-left">
          <v-col cols="4"  class="pl-4" >
            Template
          </v-col>
          <v-col cols="7"  class="pl-4">
            Subject
          </v-col>
          <v-col cols="1" class="text-left">
            Actions
          </v-col>
        </v-row>
        <div class="w-100 pa-0 mx-0  box-container">
          <v-row class="py-0 w-100 ma-0 px-0 hover-elevation" v-for="(item, i) in emailData" :key="i" style="border: 1px solid lightgray;margin-top:10px !important;margin-bottom:10px !important;border-radius:6px; ">
            <v-col cols="4" class="my-2 text-left">
              <p class="mb-0">{{item.templateTrigger.replace(/_/g, ' ')}}</p>
            </v-col>
            <v-col cols="7" class="my-2 text-left">
              <p class="mb-0" style="color: grey; font-size: 100%;white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;">{{item.subject}}</p>
            </v-col>
            <v-col cols="1" class="my-2 text-left" style="border-left: 1px solid rgba(0, 0, 0, 0.12);">
                  <v-icon class="mx-2 pa-2" color="primary" style="font-size:18px;" @click.stop="editEmail(item)" v-bind="attrs" v-on="on">mdi-note-edit-outline</v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-flex>
    <v-dialog
    style="z-index: 999;"
    persistent
  v-model="openView"
  max-width="800px"
  scrollable
>
  <v-card style="max-height:550px;">
    <v-card-title>
      <span class="headline">Edit Email</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="openView = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-flex xs12>
          <v-menu
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-width="300"
            offset-y
            left
            style="max-width:600px;z-index: 99999;"
          >
            <template v-slot:activator="{ on, attrs }">
              <p
                style="float: right; margin: 0px 5px;"
                outlined
                dark
                v-bind="attrs"
                v-on="on"
              >
                Insert placeholder
              </p>
            </template>
            <v-card>
              <v-list>
                <label class="ml-4">Subject placeholder</label>
                <v-tabs
                  vertical
                  class="text-left mt-2"
                  style="border-top: 1px solid rgba(0, 0, 0, 0.12);"
                >
                  <v-tab
                    v-for="(plc, p) in placeholders"
                    :key="p"
                    class="text-left"
                    style="display: inline-table; border-right: 1px solid #0ab39c; font-size: 12px; padding-top: 16px;"
                  >
                    {{ p }}
                  </v-tab>
                  <v-tab-item
                    class="pa-2 pt-3"
                    v-for="(plc, p) in placeholders"
                    :key="p"
                  >
                    <p
                      style="margin: 0 20px 10px 0; display: inline-flex; line-height: 18px; border-radius: 6px; padding: 5px 10px; cursor: pointer; background: rgb(237, 237, 237); font-size: 13px;"
                      v-for="(plc1, p1) in plc"
                      :key="p1"
                      @click="addName(plc1)"
                    >
                      {{ plc1 }}
                    </p>
                  </v-tab-item>
                </v-tabs>
              </v-list>
            </v-card>
          </v-menu>
          <label>Subject*</label>
          <v-text-field
            placeholder="Enter mail subject"
            outlined
            dense
            ref="my"
            v-model="subjectEmail"
            name="subject"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-width="300"
            offset-y
            left
            style="max-width:600px;z-index: 99999;"
          >
            <template v-slot:activator="{ on, attrs }">
              <p
                style="float: right; margin: 0px 5px;"
                color="primary"
                outlined
                dark
                v-bind="attrs"
                v-on="on"
              >
                Insert placeholder
              </p>
            </template>
            <v-card >
              <v-list >
                <label class="ml-4">Body placeholder</label>
                <v-tabs
                  vertical
                  class="text-left mt-2"
                  style="border-top: 1px solid rgba(0, 0, 0, 0.12);"
                >
                  <v-tab
                    v-for="(plc, p) in placeholders"
                    :key="p"
                    class="text-left"
                    style="display: inline-table; border-right: 1px solid #0ab39c; font-size: 12px; padding-top: 16px;"
                  >
                    {{ p }}
                  </v-tab>
                  <v-tab-item
                    class="pa-2 pt-3"
                    v-for="(plc, p) in placeholders"
                    :key="p"
                  >
                    <p
                      style="margin: 0 20px 10px 0; display: inline-flex; line-height: 18px; border-radius: 6px; padding: 5px 10px; cursor: pointer; background: rgb(237, 237, 237); font-size: 13px;"
                      v-for="(plc1, p1) in plc"
                      :key="p1"
                      @click="insertPlaceholder(plc1)"
                    >
                      {{ plc1 }}
                    </p>
                  </v-tab-item>
                </v-tabs>
              </v-list>
            </v-card>
          </v-menu>
    <label>Body*</label>
    <quill-editor
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)"
      ref="myQuillEditor"
      class="quill-editor-1"
      name="body"
      data-vv-as="description"
      v-model="bodyEmail"
      :options="editorOption"
    >
      <div id="toolbar" slot="toolbar" style="border-radius: 8px 8px 0 0;">
        <button class="ql-bold">Bold</button>
        <button class="ql-italic">Italic</button>
        <button class="ql-underline">Underline</button>
        <button class="ql-strike">Strike</button>
        <button class="ql-header" value="1">Header 1</button>
        <button class="ql-header" value="2">Header 2</button>
        <button class="ql-blockquote">Blockquote</button>
        <button class="ql-list" value="ordered">Ordered List</button>
        <button class="ql-list" value="bullet">Bullet List</button>
        <button class="ql-code-block">Code</button>
        <button class="ql-link">Link</button>
        <button class="ql-image">Image</button>
      </div>
    </quill-editor>
    <div  class="mt-4">
      <quill-editor
        @focus="onEditorFocus($event)"
        @change="onEditorChange($event)"
        ref="myQuillEditor1"
        class="quill-editor-2"
        name="body"
        data-vv-as="description"
        v-model="bodyEmail1"
        :options="editorOption"
      ></quill-editor>
    </div>
        </v-flex>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn class="button" depressed @click="validate()">
        Update
      </v-btn>
      <v-btn text depressed @click="openView = false">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import { mapState } from 'vuex';
export default {
  name: 'EmailTemplates',
  components: {
    quillEditor
  },
  data () {
    return {
      emailData: null,
      menu: false,
      menu2: false,
      menu3: false,
      menu1: false,
      emailID: '',
      emailList: [],
      nameRules: [
        v => !!v || 'This field is required',
      ],
      pagination: {
      rowsPerPage: 10,
      page: 1,
      length: 1
    },
      valid: true,
      emailCalendarList: [],
      editorOption: {
          modules: {
            toolbar: '#toolbar'
          },
          placeholder: 'Type here ...'
        },
        editorOption1: {
          modules: {
            toolbar: '#toolbar'
          },
          placeholder: 'Type here ...'
        },
      config: {
        editable: false,
        initialLoading: true,
        loading: false,
        initialLoadingFilters: true,
        newLogo: null,
        logoPreview: null,
        activeIndex: 0
      },
      placeholders: {"Session Details":{
        "Employee display name": "{{Session.user.display_name}}",
        "Employee first name": "{{Session.user.first_name}}",
        "Employee last name": "{{Session.user.last_name}}",
        "Employee email": "{{Session.user.email}}",
        "Employee company name": "{{Session.company.company_name}}"
    }},
      subjectEmail: '',
      templateId: '',
      subjectCalendarEmail: '',
      templateName: '',
      bodyEmail: '',
      activeIndex: 0,
      text: '',
      column: 'self',
      column1: 'self',
      text12: '',
      listview: true,
      openView: false,
      editview: false,
      tab: null,
      company: {}
    };
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  mounted () {
    this.getPlaceholders();
  },
  beforeMount () {
    this.getTemplateDetails();
  },
  watch: {
  },
  methods: {
    /* eslint-disable */
    validate () {
        this.$refs.form.validate()
        setTimeout(() => {
          if (this.valid && this.bodyEmail.length > 0) {
            this.updateEmail();
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Subject and body is mandatory field!'
            });
          }
        }, 2000);
      },
    getImgUrl (pet) {
      const images = require.context('@/assets/', false, /\.png$/);
      return images(`./${pet}.png`);
    },
    newFilter () {
      this.openView = true;
    },
    getTemplateDetails () {
      axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
      const queryParams = {
      count: 'true',
      page_limit: this.pagination.rowsPerPage,
      page_offset: this.pagination.page || 1,
      raw_search_string: this.searchCompany,
    };
      axios.get(`${process.env.VUE_APP_API_URL}company/templates/${this.$route.query.cid}?count=true`, {
        params: queryParams
      }).then((response) => {
        if (response && response.data) {
          this.loading = false;
          this.emailData = response.data.templates_details;
          this.pagination.length = Math.ceil(response.data.count / this.pagination.rowsPerPage);
        }
      }).catch((err) => {
        this.loading = false;
        if (err.response.status === 429) {
          this.config.savingStage = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to fetch details, Please try again later!'
          });
        }
      });
    },
    editEmail (item) {
      this.openView = true;
      this.editview = true;
      this.emailID = item.id;
    //   this.templateName = item.templateName;
    //   this.column = item.share_settings;
      this.subjectEmail = item.subject;
      this.templateId = item.id;
      this.bodyEmail = item.body.body_1;
      this.bodyEmail1 = item.body.body_2;
      this.bodyEmail2 = item.body.body_sub;
    },
    updateEmail () {
      const temp = {
        body_1: this.bodyEmail,
        body_2: this.bodyEmail1,
        body_sub: this.bodyEmail2,
      };
      axios.patch(`${process.env.VUE_APP_API_URL}company/templates/${this.$route.query.cid}?id=${this.templateId}`, {
        subject: this.subjectEmail,
        body: temp
      }).then((response) => {
        if (response && response.data) {
          this.listview = true;
          this.openView = false;
          this.editview = false;
          this.getTemplateDetails();
          this.bodyEmail = '';
          this.subjectEmail = '';
          this.templateName = '';
          this.$store.dispatch('updateSnackbar', {
            color: 'success',
            show: true,
            text: 'Email template updated successfully!'
          });
        }
      }).catch((err) => {
        if (err.response.status === 429) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to fetch template, Please try again later!'
          });
        }
      });
    },
    onEditorFocus(quill) {
      if (quill.getSelection()) {
        this.config.activeIndex = quill.getSelection().index;
      }
    },
    onEditorChange(q) {
      if (q.quill.getSelection()) {
        this.config.activeIndex = q.quill.getSelection().index;
      }
    },
    insertPlaceholder(p) {
      this.menu1 = false;
      const quill = this.$refs.myQuillEditor.quill;
      quill.insertText(this.config.activeIndex, p, '', true);
    },
    onEditorFocus12(quill) {
      if (quill.getSelection()) {
        this.config.activeIndex = quill.getSelection().index;
      }
    },
    onEditorChange12(q) {
      if (q.quill.getSelection()) {
        this.config.activeIndex = q.quill.getSelection().index;
      }
    },
    insertPlaceholder12(p) {
      this.menu3 = false;
      const quill = this.$refs.myQuillEditor12.quill;
      quill.insertText(this.config.activeIndex, p, '', true);
    },
    addName (plc) {
      this.menu3 = false;
      const { subjectEmail } = this;
      const textarea = this.$refs.my.$refs.input;
      const index = textarea.selectionStart;
      const name = `${plc}`;

      this.subjectEmail = `${subjectEmail.substring(0, index)}${name}${subjectEmail.substring(
        index
      )}`;
      textarea.focus();
      setTimeout(() => {
        textarea.selectionStart = index + name.length;
        textarea.selectionEnd = index + name.length;
      });
    },
    addName12 (plc) {
      this.menu2 = false;
      const { subjectCalendarEmail } = this;
      const textarea = this.$refs.my12.$refs.input;
      const index = textarea.selectionStart;
      const name = `${plc}`;

      this.subjectCalendarEmail = `${subjectCalendarEmail.substring(0, index)}${name}${subjectCalendarEmail.substring(
        index
      )}`;
      textarea.focus();
      setTimeout(() => {
        textarea.selectionStart = index + name.length;
        textarea.selectionEnd = index + name.length;
      });
    },
    addNamenew (plc1) {
      const { bodyEmail } = this;
      const textarea = this.$refs.my1.$refs.input;
      const index = textarea.selectionStart;
      const name = `${plc1}`;

      this.bodyEmail = `${bodyEmail.substring(0, index)}${name}${bodyEmail.substring(
        index
      )}`;
      textarea.focus();
      setTimeout(() => {
        textarea.selectionStart = index + name.length;
        textarea.selectionEnd = index + name.length;
      });
    },
    edit () {
        this.openView = true;
    },
    save () {
        this.listview = true;
        this.openView = false;
        this.editview = false;
        this.bodyEmail = '',
        this.subjectEmail = '',
        this.templateName = ''
    },
    some (event) {
        let textarea = this.$refs.yourTextarea.selectionStart;
        let cursorPosition = textarea.selectionStart
    }
  },
};
</script>

<style lang="scss" scoped>
.companySettingsToggel {
    .v-btn--active {
        background-color: var(--primary-color) !important;
    }
}
.desc-grp {
  padding: 20px;
  text-align: left;
  li {
    text-align: left;
    font-size: 16px;
    color: #000;
  }
}
.label-combo {
  padding-left: 2%;
  padding-bottom: 1%;
}
.disable-check {
  opacity: .5;
  pointer-events: none;
}
.enable-check {
  opacity: 1;
  pointer-events: all;
}
.combobox-group {
  padding: 0 20px;
  .combo-input {
    // border: 1.3px solid #808080;
    border-radius: 6px;
    padding: 2px 0;
    margin-bottom: 10px;
  }
}
.checkbox {
  margin-left: 12px;
}
.checkbox-text {
  margin-top: 20px;
  font-size: 16px;
}
.note {
  font-size: 16px;
  padding: 0 20px;
  color: #000;
}
.save-btn {
  margin-left: 12px;
  margin-top: 20px;
}
</style>

<style lang="scss">
.companyprofile p {
  margin-bottom: 16px;
}
.companyprofile .v-tabs-bar {
      height: 70px;
}
.box {
  color: #ffffff !important;
  .v-chip {
        // font-weight: 600;
    color: #050550 !important;
    border: 1px solid #050550;
    background-color: #ffffff;
  }
}
</style>

<style lang="scss">
.combobox-group {
  .v-input__slot {
    box-shadow: none !important;
    border: 1.3px solid #808080;
    // border-radius: 8px !important;
    padding: 6px 6px;
  }
}
.companyprofile{
  .company-logo {
  height: 200px !important;
  width: 200px !important;
}
.theme--light.v-text-field--outline .v-input__slot {
    border: 0px solid #808080 !important;
}
}
.settings-company-name {
  color: var(--color-text);
  font-size: var(--stats-size-1);
  font-weight: var(--heading-weight);
}
.heading-text {
  color: var(--color-text);
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
}
input[type=file] {
  cursor: pointer;
  width: 220px;
  height: 220px;
  margin-top: 30px;
  padding:10px;
  border-radius: 10px;
  overflow: hidden;
  background: #f1f1f1;
}

input[type=file]:before {
  content: 'Drag and drop or Click to update company logo.';
  vertical-align: middle;
  text-align: center;
  font-size: 1em;
  line-height: 1.5;
  display: inline-block;
  background: #f1f1f1;
  padding: 30% 10px;
  font-family: Helvetica, Arial, sans-serif;
}

input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
}
.v-menu__content {
  max-width: 600px !important;
}
.quill-editor-1 .ql-container {
  min-height: 200px;
  border-radius: 0 0 8px 8px ;

}
.quill-editor-2 .ql-container {
  min-height: 100px;
  border-radius:  8px ;
}
.quill-editor-3 .ql-container {
  min-height: 100px;
  border-radius:  8px ;
}
.ql-editor p {
  padding: 5px !important;
}
</style>
