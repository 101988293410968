<template>
  <div class="text-center pa-4" style="background: #f3f3f9 none repeat scroll 0 0; padding: 25px 50px 50px 50px !important;">
    <v-text-field
    class="searchEmployee"
    style="width: 20%;position: fixed;top: 12px;z-index: 15;left: 12%;background-color: #f3f3f9"
    v-if="$route.name === 'companyList'"
    v-model="searchCompany"
    placeholder="Search company"
    outlined
    dense
    hide-details>
    </v-text-field>

    <v-btn style="
      position: fixed;
      top: 15px;
      z-index: 20;
      right: 15%;color: white;" class="text-right filterButton"
      depressed @click="openModal()"
    >
      <v-icon>mdi-plus</v-icon> Add company
    </v-btn>
    <div class="headingprofile">
      <v-row class="w-100 ma-0">
        <v-col cols="12" class="pa-0">
          <v-row class="w-100 ma-0" style="background-color: white;border-radius:6px;">
            <v-col @click="changeTab('')" style="display:flex;cursor: pointer;max-width: 120px ;" class="pa-0 ">
              <span :class="$route.name === 'dashboard' ? 'activeTab' : 'hover'" style="padding:10px;display:flex;border-radius: 8px;">
                <span class="mdi mdi-view-dashboard-outline" style="padding-right:2px"></span>Dashboard
              </span>
            </v-col>
            <v-col @click="changeTab('company-list')" style="display: flex;cursor: pointer;border-radius:6px;" class="pa-0 ">
              <span :class="$route.name !== 'dashboard' ? 'activeTab' : ''" style="white-space: nowrap;padding:10px;display:flex;border-radius: 8px;text-align:center;align-items: center;justify-content: center;"><span class="mdi mdi-format-list-bulleted" style="padding-right:2px"></span>
                Company list
              </span>
            </v-col>
            <v-col  style="display:flex;cursor: pointer; justify-content: flex-end;" cols="9" class="pa-0">
              <v-btn @click="toggleDrawer" class="filterButton" style="    height: calc(100% - 10px);
    margin: 5px;
" >
                <v-badge
    v-if="filter_type || filter_status"
    :content="badgeCount"
    color="#0ab39c"
    overlap
    style="bottom: 20px;left:90px;"
  >
  </v-badge>
                <span class="mdi mdi-filter-outline" style="font-size: 20px;"></span>
              Filters
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
        </v-col>
        <v-col cols="6">
        </v-col>
      </v-row>
    </div>
    <div>
      <div class="tableDash" ref="scrollToMe">
        <v-row class="w-100 ma-0 sticky-header">
          <v-col cols="10">
            <span style="font-size: 1.7rem;">List of companies</span>
          </v-col>
        </v-row>
        <v-list two-line v-if="dashboardData && dashboardData.companies_overview && !loading" style="background-color: #f6f6f6;padding-inline:2px;">
          <v-row class="w-100 ma-0 sticky-header1" style="padding: 0 16px;border-radius:6px;">
            <v-col style="font-size:18px; font-weight:500;" cols="1">
                Country
              </v-col>
              <v-col style="font-size:18px; font-weight:500;" cols="3">
                Company
                <v-row>
                  <v-col cols="4">
                    <small style="font-weight:400;">Logo</small>
                  </v-col>
                  <v-col cols="8">
                    <small style="font-weight:400;">Name</small>
                  </v-col>
                </v-row>
              </v-col>
              <v-col style="font-size:18px; font-weight:500;" cols="1">
                Status
              </v-col>
              <v-col style="font-size:18px;font-weight:500;" cols="1">
                Created
                <v-row>
                  <v-col cols="12">
                    <small style="font-weight:400;">Date</small>
                  </v-col>
                </v-row>
              </v-col>
              <v-col style="font-size:18px; font-weight:500;" cols="1">
                Go live
                <v-row>
                  <v-col cols="12">
                    <small style="font-weight:400;">Date</small>
                  </v-col>
                </v-row>
              </v-col>
              <v-col style="font-size:18px;font-weight:500;" cols="1" class="">
                Users
                <v-row>
                  <v-col cols="12">
                    <small style="font-weight:400;">Booked</small>
                  </v-col>
                </v-row>
              </v-col>
              <v-col style="font-size:18px;font-weight:500;" cols="3" class="">
                Current users
                <v-row>
                  <v-col cols="3">
                    <small style="font-weight:400;">Active</small>
                  </v-col>
                  <v-col cols="3">
                    <small style="font-weight:400;">Inactive</small>
                  </v-col>
                  <v-col cols="3">
                    <small style="font-weight:400;">Duplicate</small>
                  </v-col>
                  <v-col cols="3">
                    <small style="font-weight:400;">Total</small>
                  </v-col>
                </v-row>
              </v-col>
              <v-col style="font-size:18px;font-weight:500;" cols="1">
                Actions
              </v-col>
            </v-row>
            <v-divider></v-divider>
          <v-list-item-group>
            <v-list-item v-for="(item) in dashboardData.companies_overview.companies_dict" :key="item" style=" margin-top:20px;margin-bottom:20px;background-color:white;border-radius: 6px;">
              <v-row class="w-100 ma-0" style="align-items: center;" @click="openDetails(item)">
                <v-col cols="1" style="text-transform: uppercase;font-size: 13px;">
                  {{item.country}}
                </v-col>
                <v-col cols="1" style="  text-align:center;border-right: 1px solid lightgray">
                  <div class="logo-container" style="margin: 2px;border:1px solid lightgray;">
                      <img v-if="item.company_logo" alt="Company Logo" :src="item.company_logo" />
                      <img v-else   src="../assets/noLogo.png" alt="image">
                    </div>
                </v-col>
                <v-col cols="2">
                  <v-row class="w-100 ma-0">
                    <v-col cols="12" class="py-0 pl-0" style="font-weight: bold;text-transform: uppercase;font-size:17px;">
                      {{item.company_name}}
                      <v-tooltip bottom v-if="item.account_type === 'Paid' || item.account_type === 'paid'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" style="font-size:17px; color:green;">
                            mdi-currency-usd
                          </v-icon>
                        </template>
                        <span>Paid</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" style="font-size:17px;" >
                            mdi-currency-usd-off
                          </v-icon>
                        </template>
                        <span>Pilot</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <v-chip v-if="item.is_active" style="background: linear-gradient(45deg,#49914E 0%,#B2D553 100%);border-radius: 10px; padding-top: 16px;padding-bottom:16px; color: white; font-size: 16px;" :color="item.is_active ? 'green' : 'grey'">
                    <span>Active</span>
                  </v-chip>
                  <v-chip v-if="!item.is_active" style="background: linear-gradient(45deg,#d63939 0%,#FF857B 100%);border-radius: 10px; padding-top: 16px;padding-bottom:16px;color: white; font-size: 16px;"  :color="item.is_active ? 'green' : 'grey'">
                    <span >Inactive</span>
                  </v-chip>
                </v-col>
                <v-col cols="1" style="">
                  {{ moment(item.createdAt).format('DD MMM YYYY') }}
                </v-col>
                <v-col cols="1" v-if="item.activatedAt">
                  {{ moment(item.activatedAt).format('DD MMM YYYY') }}
                </v-col>
                <v-col cols="1" v-else>
                  --
                </v-col>
                <v-col cols="1" style="">
                  <span v-if="item.user_booked">
                    {{item.user_booked}}
                  </span>
                  <span v-else>--</span>
                </v-col>
                <v-col cols="3" style="" v-if="item.users_status.length > 0">
                  <v-row class="w-100 ma-0">
                    <v-col cols="3">
                      <span style="font-weight:400;" v-if="item.users_status && item.users_status[0] && item.users_status[0].status === 'active'">{{item.users_status[0].count}}</span>
                      <span style="font-weight:400;" v-else-if="item.users_status && item.users_status[1] && item.users_status[1].status === 'active'">{{item.users_status[1].count}}</span>
                      <span style="font-weight:400;" v-else-if="item.users_status && item.users_status[2] && item.users_status[2].status === 'active'">{{item.users_status[2].count}}</span>
                      <span style="font-weight:400;" v-else>--</span>
                    </v-col>
                    <v-col cols="3">
                      <span style="font-weight:400;" v-if="item.users_status && item.users_status[0] && item.users_status[0].status === 'inactive'">{{item.users_status[0].count}}</span>
                      <span style="font-weight:400;" v-else-if="item.users_status && item.users_status[1] && item.users_status[1].status === 'inactive'">{{item.users_status[1].count}}</span>
                      <span style="font-weight:400;" v-else-if="item.users_status && item.users_status[2] && item.users_status[2].status === 'inactive'">{{item.users_status[2].count}}</span>
                      <span style="font-weight:400;" v-else>--</span>
                    </v-col>
                    <v-col cols="3">
                      <span style="font-weight:400;" v-if="item.users_status && item.users_status[0] && item.users_status[0].status === 'duplicate'">{{item.users_status[0].count}}</span>
                      <span style="font-weight:400;" v-else-if="item.users_status && item.users_status[1] && item.users_status[1].status === 'duplicate'">{{item.users_status[1].count}}</span>
                      <span style="font-weight:400;" v-else-if="item.users_status && item.users_status[2] && item.users_status[2].status === 'duplicate'">{{item.users_status[2].count}}</span>
                      <span style="font-weight:400;" v-else>--</span>
                    </v-col>
                    <v-col cols="3">
                      <span style="font-weight:400;" v-if="item.users_status.length === 1">{{item.users_status[0].count}}</span>
                      <span style="font-weight:400;" v-else-if="item.users_status.length === 2">{{item.users_status[0].count + item.users_status[1].count}}</span>
                      <span style="font-weight:400;" v-else-if="item.users_status.length === 3">{{item.users_status[0].count + item.users_status[1].count + item.users_status[2].count}}</span>
                      <span style="font-weight:400;" v-else>--</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3" style="" v-else>
                  <v-row class="w-100 ma-0">
                    <v-col cols="3">
                      <span style="font-weight:400;">--</span>
                    </v-col>
                    <v-col cols="3">
                      <span style="font-weight:400;">--</span>
                    </v-col>
                    <v-col cols="3">
                      <span style="font-weight:400;">--</span>
                    </v-col>
                    <v-col cols="3">
                      <span style="font-weight:400;">--</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" style="" class="companySettingsToggel">
                  <v-row class="w-100 ma-0">
                    <v-col cols="12">
                      <v-btn outlined @click.stop="editCompany(item)" style=" margin-top:-10px; min-width:40px;  width:40px; border-color:#e8edf2; border-radius:50%; color: #0ab39c !important;" color="#050550">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-list two-line v-else>
          <v-skeleton-loader v-for="(i, j) in 10" :key="j"
            v-bind="attrs"
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
        </v-list>
        <div class="d-flex">
          <v-flex xs1></v-flex>
          <v-flex class="pr-4 mb-0 ml-3" xs10 style="text-align:center">
            <v-pagination
              class="adhoc--text"
              color="#0ab39c"
              v-model="pagination.page"
              :length="pagination.length"
              :total-visible="7"
              @input="getCompanyDetails('page')"
              circle
            ></v-pagination>
          </v-flex>
          <v-flex xs1></v-flex>
        </div>
      </div>
    </div>
    <v-navigation-drawer
    style="z-index: 11;"
      v-model="drawerOpen" class="filterDrawer"
      app
      right
      temporary
      width="400"
    >
  <v-list style="margin-top: 70px;">
    <v-list-item style="align-items:left">
  <v-list-item-content>
    <v-list-item-title style="font-size:20px;font-weight:600;text-align: left;">
      Company Filters
    </v-list-item-title>
    <v-list-item-title style="text-align: left;">
      Filters will be applied to Company List
    </v-list-item-title>
  </v-list-item-content>
  <v-btn @click="resetFilters" style="border:1px solid #0ab39c;color:#0ab39c;background-color: white;box-shadow: none;text-transform: capitalize;"      v-if="filter_type || filter_status"   >
    <span class="mdi mdi-restore"></span>
    Reset
  </v-btn>
</v-list-item>
<v-expansion-panels bg-color="white" flat style="border-bottom:1px solid #0003;border-top:1px solid #0003;border-radius:0px !important;margin-left: 1px;">
  <v-expansion-panel style="padding-top:10px;padding-bottom:10px;margin-top:5px;">
        <v-expansion-panel-header>
          Company Type
          <v-chip 
        v-if="filter_type" 
        style="float: right; background-color: white;border:1px solid #0ab39c;color:#0ab39c;margin-inline: 70px;">
        1 Selected
      </v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group v-model="filter_type">
        <v-radio label="Paid" value="paid"></v-radio>
        <v-radio label="Pilot" value="pilot"></v-radio>
      </v-radio-group>
    </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels bg-color="white" flat style="border-bottom:1px solid #0003;border-radius:0px !important;margin-left: 1px;">
      <v-expansion-panel  style="padding-top:10px;padding-bottom:10px;">
        <v-expansion-panel-header>
          Company Status
          <v-chip 
        v-if="filter_status" 
        style="float: right; background-color: white;border:1px solid #0ab39c;color:#0ab39c;margin-inline: 65px;">
        1 Selected
      </v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group v-model="filter_status">
        <v-radio label="Active" value="true"></v-radio>
        <v-radio label="Inactive" value="false"></v-radio>
      </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn @click="applyFilters()" style="    background-color: #0ab39c;
    color: white;box-shadow: none;    position: absolute; text-transform: capitalize;
    bottom: 12px;
    right: 12px;">
    Apply filter
  </v-btn>
  </v-list>
    </v-navigation-drawer>
    <addCompany ref="addcompany" class="" />
  </div>
</template>

<script>
/* eslint-disable */
var count = 0;
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios';
import { mapState } from 'vuex';

import moment from 'moment';
import infiniteScroll from 'vue-infinite-scroll';
import addCompany from './dialogs/AddCompany';


am4core.useTheme(am4themes_animated);
export default {
  name: 'companyList',

  components: {
    axios,
    infiniteScroll,
    addCompany
  },

  data: () => ({
    drawerOpen: false,
    filtersMenu: false,
    moment: moment,
    data: [],
    loading: false,
    avatarList: [],
    pagination: {
      rowsPerPage: 10,
      page: 1,
      length: 1
    },
    busy: false,
    searchCompany: '',
    filter_status: null,
    filter_type: null,
    dashboardData: null,
  }),
  watch : {
    searchCompany () {
      this.getCompanyDetails()
    }
  },
  computed: {
    badgeCount() {
    return (this.filter_type && this.filter_status) ? 2 : (this.filter_type || this.filter_status) ? 1 : null;
  },
    filter_type() {
    return this.$route.query.filter_type || this.filter_type;
  },
  filter_status() {
    return this.$route.query.filter_status || this.filter_status;
  },
    ...mapState({
      drawerOpen: state => state.drawerOpen, 
      snackbar: state => state.snackbar,
      user: state => state.user
    })
  },
  methods: {
    applyFilters() {
      this.drawerOpen = !this.drawerOpen;
    this.getCompanyDetails();
  },
      toggleDrawer() {
        this.drawerOpen = !this.drawerOpen;
      this.$store.dispatch('toggleDrawer');
    },
      resetFilters () {
        this.drawerOpen = !this.drawerOpen;
        this.filter_status=null;
      this.filter_type=null;
      this.getCompanyDetails();
    },
    logout () {
    axios.post(`${process.env.VUE_APP_API_URL}logout`).then((response) => {
      if (response && response.data.status === 200) {
        this.$store.dispatch('deleteSession', 'logout');
        this.$router.push('/login');
      } else {
        this.$notify({
          group: 'foo',
          title: 'Error while logging out!',
          type: 'warn'
        });
        this.$store.dispatch('deleteSession', 'logout');
        this.$router.push('/login');
      }
    }, () => {
      this.$store.dispatch('deleteSession', 'logout');
      this.$router.push('/login');
    });
  },
  changeTab(url) {
    const queryParams = {};
    if (this.filter_type) {
      queryParams.filter_type = this.filter_type;
    }
    if (this.filter_status) {
      queryParams.filter_status = this.filter_status;
    }
    if (url === 'company-list') {
      queryParams.page = 1; 
    }
    this.$router.push({ path: `/${url}`, query: queryParams });
  },
    scrollToElement() {
  const el = this.$refs.scrollToMe;

  if (el) {
    el.scrollIntoView({behavior: 'smooth'});
  }
},
    openDetails (item) {
      this.$router.push(`/company-details?cid=${item.id}&page=${this.pagination.page}`);
    },
    applyFilter (data) {
      this.filter_type = data;
      this.getCompanyDetails();
      this.scrollToElement();
    },
    getAvatar () {
      axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
      axios.get(`${process.env.VUE_APP_API_URL}company/avatar`).then((response) => {
        if (response && response.data) {
          this.avatarList = response.data;
        }
      }).catch((err) => {
        if (err.response.status === 429) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to fetch details, Please try again later!'
          });
        }
      });
    },
    openModal () {
      this.$refs.addcompany.dialog = true;
      this.$refs.addcompany.company = {};
      this.$refs.addcompany.company.company_avatar = '';
      this.$refs.addcompany.company.company_avatar_id = '';
      this.$refs.addcompany.company.companies_avatar = this.avatarList;
      this.$refs.addcompany.company.is_active = true;
      this.$refs.addcompany.company.chat_cobranding_enabled = false;
      this.$refs.addcompany.company.allow_stage_edit = false;
    },
    loadMore() {
    this.busy = true;

      setTimeout(() => {
        for (var i = 0, j = 10; i < j; i++) {
          this.data.push({ name: count++ });
        }
        this.busy = false;
      }, 1000);
    },
    editCompany (data) {
      this.$router.push(`/company-details?cid=${data.id}&editview=true&page=${this.pagination.page}`);
    },
    getCompanyDetails (item) {
      if (this.$route.query.page && !item) {
        this.pagination.page = JSON.parse(this.$route.query.page)
      }
      if (this.$route.query.page && item) {
        this.$router.push({
          path: '/company-list',
          query: { page: this.pagination.page }
        }).catch(() => {});
        this.pagination.page = JSON.parse(this.$route.query.page)
      }
      this.loading = true;
      axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
      const queryParams = {
      count: 'true',
      page_limit: this.pagination.rowsPerPage,
      page_offset: this.pagination.page || 1,
      raw_search_string: this.searchCompany,
      active_plan__account_type: this.filter_type ? this.filter_type : undefined,
      is_active: this.filter_status  ? this.filter_status : undefined,
    };
      axios.get(`${process.env.VUE_APP_API_URL}company/dashboard?fields=companies_overview`, {
        params: queryParams
      }).then((response) => {
        if (response && response.data) {
          this.loading = false;
          this.dashboardData = response.data;
          this.pagination.length = Math.ceil(response.data.companies_overview.companies_count / this.pagination.rowsPerPage);
          if (response.data && response.data.dashboard_overview.account_type) {
            this.accountTypeChart(response.data.dashboard_overview.account_type);
            this.lineChart();
          }
          
        }
      }).catch((err) => {
        this.loading = false;
        if (err.response.status === 429) {
          this.config.savingStage = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to fetch details, Please try again later!'
          });
        }
      });
    },
  },
  mounted () {
    this.filter_type = this.$route.query.filter_type || null;
  this.filter_status = this.$route.query.filter_status || null;
    this.getCompanyDetails();
    this.getAvatar();
    this.loadMore();
    if (!this.user.email) {
      this.$router.push(`/login`);
    }
  }
}
</script>
<style lang="scss" >
.filterDrawer .v-icon.v-icon {
  font-size: 14px !important;
}
.filterDrawer .v-input--selection-controls {
  margin-top: 0px !important;
}
.heading {
  font-size: 16px;
  padding-top:10px;
}
.dashboardCount {
  font-size: 70px;
  padding:10%;
}

.mainBox {
      background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 16px 11px rgb(0 0 0 / 6%), 0 4px 8px rgb(0 0 0 / 10%) !important;
    height: 16rem;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    text-align: center;
}
.tableDash {
  text-align: left;
  padding: 10px;;
  background-color: #f6f6f6;
    border-radius: 6px;
    box-shadow: 0 4px 16px 11px rgb(0 0 0 / 6%), 0 4px 8px rgb(0 0 0 / 10%) !important; 
    margin-top:15px;
}
.v-btn--active {
    background-color: #050550 !important;
  }
.disabledView {
  .v-btn--active {
    background-color: #858585 !important;
  }
}
#chartdiv1 {
  height: 400px;
  width:100%;;
}

.primary--text {
  color: #050550 !important;
}
#chartdiv {
  width: 100%;
  height: 210px;
  
}
.headingprofile {
  text-align: left;
  h2 {
        color: #263238;
    
    // font-family: Montserrat;
    font-weight: 500;
    line-height: 1.1;
    font-size: 20px;
    margin: 0 0 6px;
    width: 100%;
  }
}
.logo-container {
    width: 100%; 
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain; 
}
.v-card__text {
  padding-top:0px;
}
.searchEmployee.v-text-field--outlined fieldset {
  border-style: none !important;
}
.sticky-header {
  position: sticky;
  top: 63px; 
  z-index: 10; 
  background-color: #f6f6f8;
}
.sticky-header1 {
  position: sticky;
  top: 125px; 
  z-index: 10; 
  background-color: #f6f6f8;
}
.filterButton {
  box-shadow: none !important;
    color: #0ab39c !important;
    background: #daf4f0 !important;
    border-color: transparent;
}
.filterButton:hover {
  box-shadow: none !important;
  color: white !important;
  background: #0ab39c !important;
  border-color: transparent !important;
}
.filterBadge {
  transition: transform 0.3s ease;
}

.shakeAnimation {
  animation: shake 0.5s ease-in-out infinite;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
}
.v-menu__content {
  z-index: 999 !important;
}
.v-btn-toggle--group > .v-btn.v-btn {
  margin-left: 0px !important;
  margin-right:0px !important;
  border-color: #d7d7d7 !important;
}
.v-btn-toggle > .v-btn.v-btn--active {
  background-color: #0ab39c !important;
  color: white !important;
}
.hover:hover {
  background-color: #0ab39c;
    color: white;
    position: relative;
    text-align: center;
    padding: 10px;
}
.hover:hover::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
  background-color: #0ab39c;
}
.toggleButton {
    text-transform: capitalize; 
}
</style>