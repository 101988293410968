<template>
  <div class="text-center pa-4" style="background: #f3f3f9 none repeat scroll 0 0; padding: 0 !important;">
    <v-card flat  style="box-shadow:none; border-radius:0;padding-inline:12px;">
      <div style="border: 1px solid lightgray; border-radius:10px; padding: 25px 30px;text-align:left;margin-top:10px;margin-bottom:10px;">
          <h3 class="my-2 text-left">Chat details</h3>
          <v-row style="width:100%;    align-items: center; margin:0; border-radius:10px; padding: 0px 30px;text-align:left;">
            <v-col cols="3" style="    padding: 31px;" v-if="companyData && companyData.summary && companyData.summary.chat_response_stats">
            <p class="mb-0 heading">Total chats</p>
            <div class="box-detail">
            <h3 style="display:inline-block; width:200px;color: rgb(77, 87, 93); font-size: 16px;padding-top:5px;padding-bottom:5px; margin: 5px 0px 0px; font-weight: 300;">{{companyData.summary.chat_response_stats.total}}</h3>
          </div>
            </v-col>
            <v-col cols="3">
              <p class="mb-0 heading">Initiated</p>
              <div class="box-detail">
              <h3 style="color: rgb(77, 87, 93); font-size: 16px;padding-top:5px;padding-bottom:5px; margin: 5px 0px 0px; font-weight: 300;" v-if="companyData.summary.chat_response_stats.initiated">{{companyData.summary.chat_response_stats.initiated}}</h3>
              <h3 style="color: rgb(77, 87, 93); font-size: 25px; margin: 5px 0px 0px; font-weight: 300;padding-top:1px;padding-bottom:1px;" v-else>--</h3>
              </div>
            </v-col>
             <v-col cols="3">
               <p class="mb-0 heading">Inprogress</p>
               <div class="box-detail">
              <h3 style="color: rgb(77, 87, 93); font-size: 16px;padding-top:5px;padding-bottom:5px; margin: 5px 0px 0px; font-weight: 300;" v-if="companyData.summary.chat_response_stats.inProgress">{{companyData.summary.chat_response_stats.inProgress}}</h3>
              <h3 style="color: rgb(77, 87, 93); font-size: 25px; margin: 5px 0px 0px; font-weight: 300;padding-top:1px;padding-bottom:1px;" v-else>--</h3>
               </div>
            </v-col>
            <v-col cols="3">
              <p class="mb-0 heading">Completed</p>
              <div class="box-detail">
              <h3 style="color: rgb(77, 87, 93); font-size: 16px;padding-top:5px;padding-bottom:5px; margin: 5px 0px 0px; font-weight: 300;" v-if="companyData.summary.chat_response_stats.completed">{{companyData.summary.chat_response_stats.completed}}</h3>
              <h3 style="color: rgb(77, 87, 93); font-size: 25px; margin: 5px 0px 0px; font-weight: 300;padding-top:1px;padding-bottom:1px;" v-else>--</h3>
              </div>
            </v-col>
          </v-row>
        </div>
        <div style="border: 1px solid lightgray; border-radius:10px; padding: 25px 30px;text-align:left;margin-top:10px;margin-bottom:10px;">
          <h3 class="my-2 text-left">User details</h3>
          <v-row style="width:100%;align-items: center; margin:0; border-radius:10px; padding: 0px 30px;text-align:left;">
            <v-col cols="3" style="padding: 31px;" v-if="companyData && companyData.summary && companyData.summary.chat_response_stats">
            <p class="mb-0 heading">Total booked users</p>
            <div class="box-detail">
            <h3 style="display:inline-block; width:200px;color: rgb(77, 87, 93); font-size: 16px;padding-top:5px;padding-bottom:5px; margin: 5px 0px 0px; font-weight: 300;" v-if="companyData.company_details.user_booked">{{companyData.company_details.user_booked}}</h3>
            <h3 style="display:inline-block; width:200px;color: rgb(77, 87, 93); font-size: 25px;padding-top:1px;padding-bottom:1px; margin: 5px 0px 0px; font-weight: 300;" v-else>--</h3>
            </div>
          </v-col>
            <v-col cols="3" v-for="(u, i) in companyData.company_details.users_status" :key="i">
              <p class="mb-0 heading text-capitalize">{{u.status}}</p>
              <div class="box-detail">
              <h3 style="color: rgb(77, 87, 93); font-size: 16px;padding-top:8px;padding-bottom:8px; margin: 5px 0px 0px; font-weight: 300;" v-if="u.count">{{u.count}}</h3>
              </div>
            </v-col>
          </v-row>
        </div>
        <div style="border: 1px solid lightgray; border-radius:10px; padding: 25px 30px;text-align:left;margin-top:10px;margin-bottom:10px;">
          <h3 class="my-2 mt-4 text-left">Email template</h3>
          <email-template ref="emailTemplate" class="" style="background-color: white;"/>
        </div>
        </v-card>
        
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import moment from 'moment';
import infiniteScroll from 'vue-infinite-scroll';
import { mapState } from 'vuex';
import emailTemplate from './emailTemplate';

export default {
  name: 'companyList',

  components: {
    axios,
    emailTemplate,
    infiniteScroll
  },
  props: {
    companyData: {
      required: true,
      type: Array
    }
  },

  data: () => ({
    moment: moment,
    data: [],
    loading: false,
    avatarList: [],
    pagination: {
      rowsPerPage: 10,
      page: 1,
      length: 1
    },
    busy: false,
    searchCompany: '',
    filter_type: null,
    emailTemplate: null,
  }),
  watch : {
  },
  computed: {
    ...mapState({
      snackbar: state => state.snackbar,
      user: state => state.user
    })
  },
  methods: {
    editCompany (data) {
      this.$router.push(`/company-details?cid=${data.id}&editview=true`);
    },
  },
}
</script>
<style lang="scss" >
.heading {
  font-size: 12px;
  padding-top:10px;
}
.dashboardCount {
  font-size: 70px;
  padding:10%;
}
.mainBox {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 16px 11px rgb(0 0 0 / 6%), 0 4px 8px rgb(0 0 0 / 10%) !important;
    height: 16rem;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    text-align: center;
}
.tableDash {
  text-align: left;
  padding: 10px;
  background-color: #f6f6f6;
    border-radius: 6px;
    box-shadow: 0 4px 16px 11px rgb(0 0 0 / 6%), 0 4px 8px rgb(0 0 0 / 10%) !important; 
    margin-top:15px;
}
.v-btn--active {
    background-color: #050550 !important;
  }
.disabledView {
  .v-btn--active {
    background-color: #858585 !important;
  }
}
#chartdiv1 {
  height: 400px;
  width:100%;
}

.primary--text {
  color: #050550 !important;
}
#chartdiv {
  width: 100%;
  height: 210px;
  
}
.headingprofile {
  text-align: left;
  h2 {
    color: #263238;
    font-weight: 500;
    line-height: 1.1;
    font-size: 20px;
    margin: 0 0 6px;
    width: 100%;
  }
}
.box-detail {
  background-color: #f6f6f8;
  padding-left:10px;
  border-radius:6px;
}
</style>
